/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */

import executeRules from '@gcc/configurator/dist/modules/api/executeRules';
import { getSelections } from '@gcc/configurator/dist/actions/rulesActions';
import { actionTypes } from '@gcc/configurator';
import transform from 'lodash/transform';
import fromPairs from 'lodash/fromPairs';
import { getPricing } from '../pricingActions';
import { mapChoiceSkuAvailability } from '../../api/skuMapperApi';
import {
  getChoiceSkuAvailabilitySuccess,
  getchoiceInit,
} from './choiceAndSkuAvailabilityAction';
import preAutoSelection from './preAutoSelection';
import postAutoSelection from './postAutoSelection';

const getAvailabilty = () => (dispatch, getState) => {
  const {
    configurator: {
      productInfo: { productId },
    },
    thdStoreInfo: { storeNumber },
    skuMap: { configurationError },
  } = getState();

  if (productId && storeNumber && !configurationError) return mapChoiceSkuAvailability(productId, storeNumber);
  return null;
};

const choiceAndSkuAvailability = ({ isStupidProduct, overrideStoreNumber } = {}) => (dispatch, getState) => {
  const {
    skuMap: { allSkus },
    choiceInit: { choiceInit },
    choiceAndSkuAvailability: {
      choiceAvailability,
      choicesBySku,
    },
    product: { details },
    config: { eleanorBaseUrl, excludedProductsFromOos },
    configurator: { choices: allChoices },
  } = getState();

  const storeId = overrideStoreNumber ?? details.siteId;

  const isProductInOosExclusionList = excludedProductsFromOos.split(',').some((e) => Number(e) === details.productId);
  let isThdStore = false;
  if (allSkus.length) {
    isThdStore = allSkus.every((skuType) => skuType === 'HomeDepot_OMSID');
  }

  const getAllNoChoiceItems = (existingChoice, noChoicesItems) => {
    let newChoices = {};
    const transformedChoice = Object.entries(existingChoice);
    for (const choiceValue of noChoicesItems) {
      const newChoice = [[choiceValue.id], true];
      transformedChoice.push(newChoice);
      newChoices = fromPairs(transformedChoice);
    }
    return newChoices;
  };
  const onFirstLoad = () => () => {
    dispatch(getAvailabilty())
      .then((response) => {
        const res = [];
        let choicesWithNo = {};
        if (!response) {
          return res;
        }
        for (const value of Object.values(response)) res.push(value);

        const [sku, choice, skuChoice, choiceSku, skuOptionChoice] = res;
        const noChoices = Object.values(allChoices)
          .filter((c) => c.internalName.substring(0, 3) === 'No_');
        choicesWithNo = getAllNoChoiceItems(choice, noChoices);
        const oosChoices = Object.keys(choicesWithNo)
          .filter((ch) => choicesWithNo[ch] === false)
          .map((c) => parseInt(c, 10));
        dispatch(getChoiceSkuAvailabilitySuccess(sku, choicesWithNo, skuChoice, choiceSku, skuOptionChoice));
        dispatch(preAutoSelection(choicesWithNo, choiceSku)).then(() => {
          const { configurator } = getState();
          const { choices, options, productInfo } = configurator;
          const current = getSelections(choices, options);
          const payload = {
            siteId: storeId,
            productId: productInfo.productId,
            selections: current,
            executeRuleSet: 'all',
          };
          executeRules(eleanorBaseUrl, payload).then((rulesData) => {
            const { data } = rulesData;
            dispatch(postAutoSelection(data, oosChoices));
          });
        });
        return dispatch(getchoiceInit(true));
      });
  };

  const excuteAvailability = () => () => {
    dispatch(preAutoSelection(choiceAvailability, choicesBySku)).then(() => {
      const { configurator } = getState();
      const {
        choices, options, productInfo, ajaxStatus: { saveAttempts },
      } = configurator;
      const current = getSelections(choices, options);
      const payload = {
        siteId: storeId,
        productId: productInfo.productId,
        selections: current,
        executeRuleSet: 'all',
      };
      executeRules(eleanorBaseUrl, payload).then((rulesData) => {
        const { data } = rulesData;
        dispatch({
          type: actionTypes.EXECUTE_RULES_SUCCESS,
          data,
          saveAttempts,
          disabledChoices: transform(data.disabledChoices, (obj, it) => (obj[it] = choices[it]), {}),
          selectedChoices: data.selectedChoices,
          choices,
          options,
        });
        dispatch(getPricing());
      });
    });
  };

  if (!choiceInit && isThdStore && !isProductInOosExclusionList) {
    dispatch(onFirstLoad());
  } else if (choiceInit && isThdStore && !isProductInOosExclusionList) {
    dispatch(excuteAvailability());
  }
};

export default choiceAndSkuAvailability;
