import { takeLatest, select, spawn, all } from 'redux-saga/effects';
import { stripIndent } from 'common-tags';
import safeSaga from '../safeSaga';
import { THD_PRODUCT_DETAILS_UPDATED } from '../../actions/actionTypes';
import {
  thdStoreInfoSelector,
  productsWithNoStockSelector,
} from '../../selectors';
import { log, LogLevels } from '../../api/logApi';

function* verifyStock() {
  const { storeNumber } = yield select(thdStoreInfoSelector);
  const products = yield select(productsWithNoStockSelector);

  yield all(products.map((product) => spawn(
    log,
    stripIndent`Out of Stock for Store Number: ${storeNumber}; Product Name: ${product.productName}; Product Brand: ${product.productBrand};`,
    LogLevels.Error,
  )));
}

export default function* stockVerificationSaga() {
  yield takeLatest(THD_PRODUCT_DETAILS_UPDATED, safeSaga(verifyStock));
}
