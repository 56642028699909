import {
  UPDATE_LINEITEM,
  UPDATE_LINEITEM_GUID
} from './actionTypes';
import { createAction } from '../utils/createAction';

export const updatelineItemId = (lineItemGuid) => (dispatch) => {

  dispatch({
    type: UPDATE_LINEITEM_GUID,
    lineItemGuid,
  });
  return createAction(UPDATE_LINEITEM, 'lineItemId');
};