import styled from 'styled-components';

// product_sku_Overlay_FinishType form-input__label
// eslint-disable-next-line import/prefer-default-export
export const Header = styled.div.attrs({
  // for thd styling
  className: 'product_sku_Overlay_FinishType form-input__label',
})`
  display: block;
  color: #666;
  padding: 0;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;

  & > span {
    font-size: 16px;
    color: #262626;
  }
`;

export const Definition = styled.div`
  padding: 2em;
  text-align: left;

  img {
    display: block;
    padding: 1.5em;
  }

  h3 {
    font-size: 1.25em;
    margin-top: 2em;
  }

  hr {
    margin-top: 0.5em;
  }

  p {
    font-size: 1em;
  }

  h3 + p,
  p + p {
    margin-top: 1em;
  }

  @media (min-width: 992px) {
    max-height: 500px !important;
    overflow: auto;
  }
`;
