import { UPDATE_PATH } from '../actions/actionTypes';

// const document = {};
const urlInitial = 'https://xyz'; // document?.querySelector('link[rel=canonical]')?.getAttribute('href');

export default (state = { url: urlInitial }, action) => {
  switch (action.type) {
  case UPDATE_PATH: {
    const { url } = action;
    return {
      ...state,
      url,
    };
  }
  default:
    return state;
  }
};
