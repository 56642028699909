import axios from 'axios';
import config from '../config';
import toCamelCase from '../utils/camelCase';
import { mergeRules } from '../utils/compatibilityRules';

export const getShippingOptions = (itemId, zipCode) => {
  const url = `${config.eleanorBaseUrl}/api/shipping/GetDeliveryOptions`;

  const params = { itemId, zipCode };

  return axios.get(url, { params })
    .then((response) => response.data);
};

export const getMsrpPricingFromAutobahn = (params) => {
  const url = `${config.eleanorBaseUrl}/api/pricing/product`;
  return axios.post(url, params)
    .then((response) => toCamelCase(response.data));
};

export const getSpecialOrderDeliveryTime = (siteId, partnerProductId) => {
  const url = `${config.eleanorBaseUrl}/api/shipping/estimatedelivery/specialorder`;

  const params = { siteId, partnerProductId };

  return axios.post(url, params)
    .then((response) => toCamelCase(response.data));
};

export const executeRules = (selectionData) => {
  const route = 'ExecuteRulesViaPost';
  const url = `${config.eleanorBaseUrl}/catalog/productconfiguration/${route}`;

  return axios({
    data: selectionData,
    url,
    method: 'POST',
    crossDomain: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(toCamelCase); // intentional. for use by configurator
};

export const executeAllRules = (selectionData) => {
  const url = {
    base: `${config.eleanorBaseUrl}/catalog/productconfiguration`,
    productRulesEndpoint: 'ExecuteRulesViaPost',
    compatibilityRulesEndpoint: 'ExecuteCompatibilityRulesViaPost',
  };

  const promiseProductRules = axios({
    data: selectionData,
    url: `${url.base}/${url.productRulesEndpoint}`,
    method: 'POST',
    crossDomain: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const promiseCompatibilityRules = axios({
    data: selectionData,
    url: `${url.base}/${url.compatibilityRulesEndpoint}`,
    method: 'POST',
    crossDomain: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return Promise.all([promiseProductRules, promiseCompatibilityRules])
    .then(mergeRules);
};
