import { useEffect, useState } from 'react';
import forEach from 'lodash/forEach';
import noop from 'lodash/noop';
import map from 'lodash/map';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import store from '../store/store';
import choiceAndSkuAvailability from '../actions/outOfStock/choiceAndSkuAvailability';
import { mapskus } from '../actions/fulfillmentActions/getItemsBySkuMap';
import { logToNewRelic } from '../utils/logNewRelicCustom';

export const useProductConfiguratorGraphDataChange = (props) => {
  const {
    fulfillmentMethod,
    fulfillment,
    fulfillmentData,
    methodData,
    productInfoData,
    cart,
    attributesData,
    onChangeGraphData,
    cartProduct,
    skuMap,
    method,
    thdStoreInfo,
    qty,
    deliveryTime,
    items,
    storeNumber,
    newData,
    parsedDeliveryData,
  } = props;

  let { itemDetails } = props;

  useEffect(() => {
    if (
      fulfillmentMethod === 'BOPIS'
      || fulfillmentMethod === 'ShipToHome'
      || fulfillmentMethod === 'DeliverFromStore'
      || fulfillmentMethod === 'DirectDelivery'
    ) {
      const newFulfillLocation = method === 'ShipToStore' || method === 'BOPIS'
        ? thdStoreInfo?.storeNumber
        : thdStoreInfo?.zipcode;
      itemDetails = map(cart, (x) => ({
        itemId: x.itemId,
        storeId: storeNumber,
        zipCode: thdStoreInfo?.zipcode,
        quantity: qty || x.quantity,
        fulfillmentMethod: method,
        fulfillmentLocation: newFulfillLocation,
      }));

      const newPropFulfilData = {
        product: {
          configuration: {
            deliveryTime,
            cart: itemDetails,
            items,
          },
        },
      };
      const parsedFulfillmentData = merge(
        {},
        { ...newData },
        { ...parsedDeliveryData },
        { ...newPropFulfilData }
      );

      return onChangeGraphData(parsedFulfillmentData);
    }

    if (fulfillmentMethod === 'ShipToStore') {
      if (cartProduct?.fulfillment?.fulfillmentOptions?.[0]?.services?.[0]?.type !== 'boss') {
        delete cartProduct?.fulfillment?.fulfillmentOptions;
      }
      let startDate;
      let endDate;
      const shippingObj = fulfillment?.fulfillmentOptions
        ? merge({}, fulfillment.fulfillmentOptions)
        : null;
      if (skuMap.isSpecialOrder) {
        startDate = deliveryTime?.startDate;
        endDate = deliveryTime?.endDate;
      }
      const shippingArr = [];
      // eslint-disable-next-line lodash/prefer-map
      forEach(shippingObj, (x) => {
        shippingArr.push(x);
      });
      if (startDate && endDate) {
        forEach(shippingObj, (o) => {
          o.services.find((d) => {
            // eslint-disable-next-line no-param-reassign
            d.deliveryDates = { startDate, endDate };
            return d;
          });
        });
      }
      const newPropDeliveryData = {
        product: {
          fulfillment: {
            ...fulfillment,
            fulfillmentOptions: shippingArr,
          },
          availabilityType: {
            type: null,
          },
        },
      };
      const newFulfillLocation = method === 'ShipToStore' || method === 'BOPIS'
        ? thdStoreInfo?.storeNumber
        : thdStoreInfo?.zipcode;
      itemDetails = map(cart, (x) => {
        const choiceQuantity = skuMap.subSkus.find((s) => Number(s.sku) === x.itemId);
        const multipliedQuantity = choiceQuantity ? choiceQuantity.multiplier * qty : qty;
        return {
          itemId: x.itemId,
          storeId: storeNumber,
          zipCode: thdStoreInfo?.zipcode,
          quantity: multipliedQuantity || x.quantity,
          fulfillmentMethod: method || fulfillmentMethod,
          fulfillmentLocation: newFulfillLocation,
        };
      });

      const newPropCartData = {
        product: {
          configuration: {
            deliveryTime,
            cart: itemDetails,
            items,
          },
        },
      };

      const parsedBossData = merge(
        {},
        { ...newData },
        { ...newPropDeliveryData },
        { ...newPropCartData }
      );

      if (newPropCartData?.product?.configuration?.cart !== cartProduct?.configuration?.cart) {
        return onChangeGraphData(parsedBossData);
      }
    }
    if (!fulfillmentMethod && cartProduct?.fulfillment?.fulfillmentOptions) {
      delete cartProduct?.fulfillment?.fulfillmentOptions;
      const newUnavailableData = {
        product: {
          availabliltyType: {
            type: null,
          },
        },
      };

      const parsedUnavailableData = merge({}, { product: cartProduct }, { ...newUnavailableData });
      return onChangeGraphData(parsedUnavailableData);
    }
    return noop;
  }, [
    fulfillmentMethod,
    fulfillment?.fulfillmentOptions,
    fulfillmentData,
    methodData,
    productInfoData.primary,
    cart?.[0]?.itemId,
    attributesData,
  ]);
};

export const useProductConfiguratorMounted = (isPreview) => {
  const [productConfiguratorMounted, setProductConfiguratorMounted] = useState(false);

  useEffect(() => {
    setProductConfiguratorMounted(true);
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator-mounted', { configurator: true, isPreview });

    if (window.LIFE_CYCLE_EVENT_BUS !== undefined) {
      window.LIFE_CYCLE_EVENT_BUS.on('add-to-cart.error', (err) => {
        logToNewRelic('add-to-cart-failure', err);
      });
      // This error comes from Add to cart confirmation component
      window.LIFE_CYCLE_EVENT_BUS.on('cart.cartPageAction', ({ output }) => {
        const { actionName, actionObject } = output;
        if (actionName === 'atcNewRelicErrorLog') {
          logToNewRelic(actionName, actionObject);
        }
      });
    }

    // window.LIFE_CYCLE_EVENT_BUS.on('buybox-zipcode-updated', (newZipCode) => {
    //   setZipCode(newZipCode.output);
    // });

    // ALWAYS DISABLE DELIVERY TIMER FOR PIPFIGS
    window.LIFE_CYCLE_EVENT_BUS.trigger('configurator.disable_timer', true);

    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('add-to-cart.error');
      window.LIFE_CYCLE_EVENT_BUS.off('buybox-zipcode-updated');
      LIFE_CYCLE_EVENT_BUS.off('configurator.configurator-mounted');
    };
  }, []);

  return { productConfiguratorMounted };
};

export const useProductConfiguratorAttributes = (props) => {
  const { attributes, setIsMajorAppliance, onChangeGraphData, parsedData } = props;

  useEffect(() => {
    const attExists = attributes?.isMajorAppliance === 'True';

    if (!attExists && !isEmpty(attributes)) {
      setIsMajorAppliance(false);
    }

    if (attributes.bypassSkuMap === 'True' || attributes.hasSkuMap === 'False') {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.loading', false);
      return onChangeGraphData(parsedData);
    }
    return noop;
  }, [attributes]);
};

export const useProductConfiguratorErrors = (props) => {
  const { skuMap, configurationErrorData, err } = props;

  useEffect(() => {
    if (skuMap?.configurationError) {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_unavailable', {
        configuratorUnavailable: true,
      });
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.loading', false);
      logToNewRelic('skumap-configuration-error', skuMap?.configurationError);
    }

    if (!isEmpty(err)) {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_unavailable', {
        configuratorUnavailable: true,
      });
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.loading', false);
      console.log(err);
      logToNewRelic('add-to-cart-error', err);
    }
  }, [configurationErrorData, err]);
};

export const useProductConfiguratorIsSpecialProduct = (props) => {
  const { skuMap, customATC } = props;

  useEffect(() => {
    if (skuMap?.isSpecialOrder) {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.isSpecialOrder', { isSpecialOrder: true });
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.isSpecialOrderATC', { customATC });
    } else {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.isSpecialOrder', { isSpecialOrder: false });
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.isSpecialOrderATC', { customATC: null });
    }
  }, [skuMap?.isSpecialOrder]);
};

export const useProductConfiguratorSubscribe = (props) => {
  const { configuratorSubscribe, frequency, onChangeGraphData } = props;
  let { newData } = props;

  useEffect(() => {
    if (configuratorSubscribe) {
      newData.product.configuration.cart[0].subscriptions = {
        ogModule: 'pdp_nocontent',
        frequency,
      };

      const newPropSubscriptonData = {
        product: {
          subscription: true,
          configuration: {
            cart: newData.product.configuration.cart,
          },
        },
      };
      const parsedSubscriptonData = merge({}, { ...newData }, { ...newPropSubscriptonData });

      return onChangeGraphData(parsedSubscriptonData);
    }
    delete newData?.product?.configuration?.cart[0]?.subscriptions;
    newData.product.subscription = false;
    const newPropSubscriptonData = {
      product: {
        subscription: false,
        configuration: {
          cart: newData.product.configuration.cart,
        },
      },
    };
    const parsedSubscriptonData = merge({}, { ...newData }, { ...newPropSubscriptonData });
    return onChangeGraphData(parsedSubscriptonData);

  }, [configuratorSubscribe, frequency]);
};

export const useProductConfiguratorAssebleItemAt = (props) => {
  const {
    assembleItemAtStoreData,
    assembleItemAtHomeData,
    productAddOnsData,
    assembleItemAtHome,
    assembleItemAtStore,
    onChangeGraphData,
    cartProduct,
  } = props;
  let { newData } = props;

  useEffect(() => {
    const warrantyItem = productAddOnsData?.warrantyItem;

    if (
      warrantyItem?.itemId
      && newData?.product?.configuration?.cart?.[0]
      && newData?.product?.configuration?.cart?.[0]?.warrantyItem?.itemId !== warrantyItem?.itemId
    ) {
      newData.product.configuration.cart[0].warrantyItem = warrantyItem;
      const parsedWarrentyData = merge({}, { ...newData });
      return onChangeGraphData(parsedWarrentyData);
    }

    if (!warrantyItem?.itemId && newData?.product?.configuration?.cart?.[0]?.warrantyItem?.itemId) {
      delete newData?.product?.configuration?.cart?.[0]?.warrantyItem;
      return onChangeGraphData(newData);
    }
    if (
      assembleItemAtHome
      && newData?.product?.configuration?.cart?.[0]
      && newData?.product?.configuration?.cart?.[0]?.serviceLineItems?.lineItem?.[0]?.storeSKU
        !== productAddOnsData?.attachedLabor?.[0]?.attachedLaborSku
    ) {
      newData.product.configuration.cart[0].attachedLabor = productAddOnsData?.attachedLabor;
      const parsedAssembleData = merge({}, { ...newData });
      if (
        cartProduct?.configuration?.cart?.[0]?.attachedLabor?.[0]?.attachedLaborSku
        !== productAddOnsData?.attachedLabor?.[0]?.attachedLaborSku
      ) {
        return onChangeGraphData(parsedAssembleData);
      }
    }
    if (!assembleItemAtHome && newData?.product?.configuration?.cart?.[0]?.attachedLabor) {
      delete newData.product.configuration.cart[0].attachedLabor;
      const parsedAssembleData = merge({}, { ...newData });

      return onChangeGraphData(parsedAssembleData);
    }
    if (
      assembleItemAtStore
      && newData?.product?.configuration?.cart?.[0]
      && newData.product.configuration.cart[0].assembleItemAtStore !== 'Y'
    ) {
      newData.product.configuration.cart[0].assembleItemAtStore = 'Y';
      const parsedSubscriptonData = merge({}, { ...newData });

      return onChangeGraphData(parsedSubscriptonData);
    } if (
      assembleItemAtStore === false
      && newData?.product?.configuration?.cart?.[0]?.assembleItemAtStore
    ) {
      delete newData.product.configuration.cart[0].assembleItemAtStore;
      const parsedSubscriptonData = merge({}, { ...newData });
      return onChangeGraphData(parsedSubscriptonData);
    }
    return noop;
  }, [assembleItemAtStoreData, assembleItemAtHomeData, productAddOnsData]);
};

export const useProductConfiguratorMapSkus = (props) => {
  const {
    productInfoData, productInfo, config, skuMap, cartProduct
  } = props;

  useEffect(() => {
    mapskus(productInfo, config, skuMap, cartProduct?.fulfillment);
  }, [productInfoData.primary]);
};

export const useProductConfiguratorSetZipCode = (props) => {
  const { setZipCode, thdStoreInfo } = props;

  useEffect(() => {
    setZipCode(thdStoreInfo.deliveryZip);
  }, [thdStoreInfo.deliveryZip]);
};

export const useProductConfiguratorFireRules = (props) => {
  const { fireRulesData } = props;

  useEffect(() => {
    if (fireRulesData === true) {
      store.dispatch(choiceAndSkuAvailability({ isStupidProduct: true }));
    }
  }, [fireRulesData]);
};

export default {
  useProductConfiguratorGraphDataChange,
  useProductConfiguratorMounted,
  useProductConfiguratorAttributes,
  useProductConfiguratorIsSpecialProduct,
  useProductConfiguratorSubscribe,
  useProductConfiguratorAssebleItemAt,
  useProductConfiguratorMapSkus,
  useProductConfiguratorSetZipCode,
  useProductConfiguratorFireRules,
};
