import flow from 'lodash/fp/flow';
import values from 'lodash/values';
import flatMap from 'lodash/fp/flatMap';
import some from 'lodash/fp/some';

const doAnyOptionsHaveErrors = flow(
  values,
  flatMap((opt) => opt.messages || []),
  some((msg) => msg.messageType.toLowerCase() === 'error'),
);

export default doAnyOptionsHaveErrors;
