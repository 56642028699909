import filter from 'lodash/fp/filter';

export default (customSizesEnabled, choices, moreSizes, showMoreSizes) => {
  const moreSizesSelected = showMoreSizes && !moreSizes.isSelected;

  if (!customSizesEnabled) {
    return filter((choice) => choice.id !== moreSizes.id)(choices);
  }

  if (!moreSizesSelected) {
    return choices;
  }

  return choices.map((choice) => {
    if (choice.id === moreSizes.id) {
      return {
        ...choice,
        isSelected: true,
      };
    } if (choice.isSelected) {
      return {
        ...choice,
        isSelected: false,
      };
    }
    return choice;
  });
};
