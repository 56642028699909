import React from 'react';
import withOnClickOutside from 'react-onclickoutside';
import RenderNative from './renderNative';
import Option from './Option';
import Value from './Value';
import { ThdSelectInput, ContentWrapper } from './styles';
import withSelect from '../../utils/withSelect';
import SelectWrapper from './selectWrapper';
import { commonDefaults, commonPropTypes } from './_commonProps';

class RenderNonNative extends React.Component {
  state = {
    isActive: false,
  };

  handleMouseDown = (e) => {
    e.preventDefault();
  }

  handleClickOutside = (e) => this.closeSelect(e);

  handleChange = (option) => (e) => {
    this.closeSelect(e);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onChange(option);
  };

  toggleSelect = (e) => {
    const { disabled } = this.props;
    e.stopPropagation();

    if (!disabled) {
      this.setState((prevState) => ({ isActive: !prevState.isActive }));
    }
  };

  openSelect = (e) => {
    e.stopPropagation();
    this.setState(() => ({ isActive: true }));
  };

  closeSelect = (e) => {
    e.stopPropagation();
    this.setState(() => ({ isActive: false }));
  };

  selectOptions = () => {
    const {
      renderer,
      optionRenderer,
      options,
      keyBy,
      hrefBy,
      indexable,
    } = this.props;
    const OptionRenderer = renderer || optionRenderer;

    return options.map((option) => (
      <li
        role="presentation"
        key={keyBy(option)}
        onClick={this.handleChange(option)}
        onMouseDown={this.handleMouseDown}
      >
        <Option href={hrefBy(option)} indexable={indexable}>
          <OptionRenderer value={option} />
        </Option>
      </li>
    ));
  };

  render() {
    const { isActive } = this.state;
    const {
      renderer,
      valueRenderer,
      label,
      disabled,
      value,
      isWrapperOpen,
      nativeRenderer,
      ...rest
    } = this.props;
    const ValueRenderer = renderer || valueRenderer;

    return (
      <SelectWrapper {...rest} isActive={isActive}>
        {label && <div className="attribute_label">{label}</div>}
        {
          nativeRenderer && <RenderNative {...this.props} />
        }
        <ThdSelectInput
          onClick={this.toggleSelect}
          onBlur={this.closeSelect}
          disabled={disabled}
          keepHidden={nativeRenderer}
        >
          <Value disabled={disabled}>
            <ValueRenderer value={value} />
          </Value>
        </ThdSelectInput>
        <ContentWrapper
          isOpen={isWrapperOpen}
          keepHidden={nativeRenderer}
        >
          <ul className="listOptions drop-down__list">{this.selectOptions()}</ul>
        </ContentWrapper>
      </SelectWrapper>
    );
  }
}

RenderNonNative.propTypes = commonPropTypes;
RenderNonNative.defaultProps = commonDefaults;

export default withSelect(withOnClickOutside(RenderNonNative));
