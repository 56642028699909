/* eslint-disable import/no-anonymous-default-export */

import filter from 'lodash/filter';
import map from 'lodash/map';
import identity from 'lodash/identity';
import startsWith from 'lodash/startsWith';

const delimiter = ':';

// Temporary Solution (TM) until TagType column/property is added to tags
export default ({ tags }, tagType, textTransform = identity) => {
  const query = textTransform(`${tagType}${delimiter}`);
  const matches = filter(tags, (tag) => startsWith(textTransform(tag), query));

  return map(matches, (match) => {
    const [, tagValue] = match.split(delimiter);

    return tagValue;
  });
};
