import deepFreeze from 'deep-freeze';

export const getDefaultStoreDetails = () => ({
  storeInfo: {},
  pricing: {
    each: true,
    originalPrice: 0,
    specialPrice: 0,
  },
  fulfillmentOptions: {
    // deliverFromStore,
    // buyOnlinePickupInStore,
    // buyOnlineShiptoStore,
  },
  inventory: {
    onHandQuantity: 0,
  },
  productName: '',
  productBrand: '',
  storeSkuNumber: '',
  modelNumber: '',
});

export const getInitialProductState = () => ({
  storeInfo: {
    national: getDefaultStoreDetails(),
  },
  isSpecialBuy: false,
  isLowerPrice: false,
  shipping: {
    // bossEstimatedShippingStartDate,
    // bossEstimatedShippingEndDate,
  },
  itemId: -1,
  media: {},
});

export const DefaultProduct = deepFreeze(getInitialProductState());

export const initialState = { DefaultProduct };
