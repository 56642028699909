/* eslint-disable import/no-cycle */
import ColorTemplate from './ColorDropdownTemplate';
import DefaultTemplate from './DefaultTemplate';
import TileOrDropdownTemplate from './TileOrDropdownTemplate';
import DropdownTemplate from './DropdownTemplate';
import SixteenthInchDropdownTemplate from './SixteenthInchDropdownTemplate';
import EighthInchDropdownTemplate from './EighthInchDropdownTemplate';
import { CustomSizingTemplate, CustomSizingDropdowns } from './CustomSizingTemplate';
import HiddenTemplate from './HiddenTemplate';

const templates = {
  Hidden: HiddenTemplate,
  Color: ColorTemplate,
  Default: DefaultTemplate,
  Dropdown: DropdownTemplate,
  SixteenInchDropdown: SixteenthInchDropdownTemplate,
  EighthInchDropdown: EighthInchDropdownTemplate,
  TileAndDropdown: TileOrDropdownTemplate,
  TileAndDropdownWithCustomSizing: CustomSizingTemplate,
  TileWithCustomSize: CustomSizingTemplate,
  CustomSize: CustomSizingDropdowns,
};

export default templates;
