import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({
  level,
  className,
  children: message,
  ...rest
}) => (
  <div
    className={`alert-inline alert-inline--${level} ${className}`}
    {...rest}
  >
    {message && <span className="alert-inline__message">{message}</span>}
  </div>
);

Alert.propTypes = {
  level: PropTypes.oneOf([
    'danger',
    'warning',
    'information',
    'success',
  ]).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

Alert.defaultProps = {
  className: '',
  children: null,
};

export default Alert;
