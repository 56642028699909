export default (state, { newSkus }) => {
  // The SKUMapper API controller is (for now) unaware of SKU unit calculation.
  const newSkusMultiplied = {
    ...newSkus,
    multiplier: 1,
    subSkus: newSkus.subSkus.map((sku) => ({
      ...sku,
      multiplier: sku.multiplier || 1,
    })),
  };

  return {
    ...state,
    ...newSkusMultiplied,
    isLoading: false,
    isSpecialOrder: newSkusMultiplied.skuType !== 'HomeDepot_OMSID',
  };
};
