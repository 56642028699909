import { parse, isValid, format } from 'date-fns';

export function parseDateByFormatString(dateString, formatString) {
  if (!dateString || !formatString) {
    return null;
  }

  const parsedDate = parse(dateString, formatString, new Date());

  // Return the parsed date if it is valid, otherwise return null
  return isValid(parsedDate) ? parsedDate : null;
}

export function convertFromUTCToLocal(date, formatString) {
  if (!date || !formatString) {
    return null;
  }
  const localDate = new Date(date);
  const formattedDate = format(localDate, formatString);

  return formattedDate;
}