import { createSelector } from 'reselect';
import productsForCartSelector from './productInfo/productsForCartSelector';
import productForDisplaySelector from './productInfo/productForDisplaySelector';
import { skusSelector } from './skuMap/skusSelector';
import getPricingForProducts from '../utils/getLocalOrNationalPricing/_getPricingForProducts';
import { findMultiplier } from './utils';

const withMultiplier = (skus) => (product) => ({
  ...product,
  multiplier: findMultiplier(skus, product.itemId),
});

const actualCartPricingSelector = createSelector(
  productsForCartSelector,
  productForDisplaySelector,
  skusSelector,
  (products, displayProduct, skus) => {
    const multiply = withMultiplier(skus);

    const displayProd = multiply(displayProduct);

    const prods = products.map(multiply);

    return getPricingForProducts(prods, displayProd);
  },
);

export default actualCartPricingSelector;
