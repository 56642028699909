export function configuratorPricingLifeCycleEventBusTrigger({ configuratorPricing }) {
  const overridePricingData = {
    configuratorPricing: {
      ...configuratorPricing
    },
    configuredPrice: {
      ...configuratorPricing,
      pricing: {
        ...configuratorPricing.pricing,
        // Setting alternatePriceDisplay and unitOfMeasure allows us to show
        // default messaging for consumer credit card when price is 0 aka
        // configuration is unavailable
        alternatePriceDisplay: !configuratorPricing?.pricing?.value,
        unitOfMeasure: !configuratorPricing?.pricing?.value ? 'uom' : 'each'
      }
    }
  };

  LIFE_CYCLE_EVENT_BUS.trigger(
    'configurator.configurator_pricing_event',
    overridePricingData
  );
}
