import get from 'lodash/fp/get';
import union from 'lodash/union';
import toCamelCase from './camelCase';

export const mergeRules = (rulesResults) => {
  const rulesResultsObj = toCamelCase(rulesResults);
  const rulesResultsBase = get('0', rulesResultsObj);

  const productRulesData = get('data.data', rulesResultsBase);
  const compatibilityRulesData = get('1.data.data', rulesResultsObj);

  try {
    const mergedRules = {
      ...rulesResultsBase,
      data: {
        ...productRulesData,
        invokedRules: union(
          productRulesData.invokedRules,
          compatibilityRulesData.invokedRules,
        ),
        disabledChoices: union(
          productRulesData.disabledChoices,
          compatibilityRulesData.disabledChoiceIds,
        ),
        messages: union(
          productRulesData.messages,
          compatibilityRulesData.ruleMessages,
        ),
        summaryMessages: union(
          productRulesData.summaryMessages,
          compatibilityRulesData.ruleMessages,
        ),
      },
    };

    return { data: mergedRules };
  } catch (err) {
    return err;
  }
};
