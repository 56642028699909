import includes from 'lodash/includes';
import {
  HYDRATION_START,
  HYDRATION_COMPLETE,
} from '../actions/hydration/orchestration';

export const hydrationReducer = (hydration = {}, action) => {
  switch (true) {
  case includes(action.type, HYDRATION_START): {
    const {
      payload: start,
    } = action;

    return { ...hydration,
      ...hydration,
      [action.meta.feature]: {
        startTime: start.startTime.dateTime,
        startTimeUnix: start.startTime.unixTime,
        hydrated: start.hydrated,
        next: start.next,
        showSkeleton: !!start.showSkeleton,
      }, };
  }
  case includes(action.type, HYDRATION_COMPLETE): {
    const {
      payload: end,
    } = action;

    const {
      feature,
    } = action.meta;

    return { ...hydration,
      ...hydration,
      [feature]: {
        ...hydration[feature],
        endTime: end.endTime.dateTime,
        hydrated: end.hydrated,
        hydrationTime: end.endTime.unixTime - hydration[feature].startTimeUnix,
      }, };
  }
  default:
    return hydration;
  }
};
