/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import keyBy from 'lodash/fp/keyBy';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import TileOrDropdownTemplate from '../TileOrDropdownTemplate/Component';
import CustomSizingDropdowns from './CustomSizingDropdowns';
import ConfiguratorTemplate from '../ConfiguratorTemplate';
import getTileChoices from './_getTileChoices';

const getMoreSizes = ({ choices }) => find(choices, (bb) => {
  if (bb.tags) {
    return bb.tags.find((cc) => cc === 'custom-size');
  }

  return null;
});

const WithCustomSizing = (props) => {
  const {
    option,
    options,
    choices,
    allEnabledChoices,
    onChoiceSelected,
    onChoiceSelectedSuccess,
    attributes,
  } = props;

  const moreSizes = getMoreSizes(props);

  // Create bool from string
  const customSizesEnabled = attributes.customSizesOnDotcom === 'True';

  const [showMoreSizes, setShowMoreSizes] = useState(moreSizes.isSelected);

  const { Width: customWidth, Height: customHeight } = flow(
    map((optId) => options[optId]),
    keyBy((opt) => opt.presentationName),
  )(moreSizes.dependentOptions);

  const getValue = (Option) => allEnabledChoices[Option.choices[0]].value;

  const updateDimension = (Option) => (choiceValue) => {
    const currentValue = getValue(Option);

    // The configurator will blank-out the selected choice's
    // value if we tell it to update to the same value.
    return (
      currentValue !== choiceValue
      && onChoiceSelected(Option.id, Option.choices[0], choiceValue)
    );
  };

  const updateWidth = updateDimension(customWidth);
  const updateHeight = updateDimension(customHeight);

  const updateCustomDimensionsWithPreset = (presetChoice) => {
    if (!presetChoice) return;
    const [, width, height] = presetChoice.internalName.split('_').map(Number);

    updateWidth(width);
    updateHeight(height);
  };

  const selectedChoice = find(choices, 'isSelected');
  if (moreSizes !== selectedChoice) {
    updateCustomDimensionsWithPreset(selectedChoice);
  }

  const updateCustomHeightAndWidth = (optionId, choiceValue) => {
    const width = optionId === customWidth.id
      ? choiceValue
      : getValue(customWidth);

    const height = optionId === customHeight.id
      ? choiceValue
      : getValue(customHeight);

    return { width, height };
  };

  const convertToDimensions = (width, height) => `_${width}_${height}`;

  const findPresetChoice = (width, height) => {
    const singleDimensions = convertToDimensions(width, height);

    return find(choices, (cc) => cc.internalName === singleDimensions);
  };

  const handleCustomSizingSelect = (optionId, choiceId, choiceValue) => {
    const { width, height } = updateCustomHeightAndWidth(optionId, choiceValue);

    const presetChoice = findPresetChoice(width, height);

    return Promise.all([
      // always update changed width/height
      onChoiceSelected(optionId, choiceId, choiceValue),
      // autoselect preset size choice (if one matches),
      // if not, make sure more sizes is set to blow up the skumap
      presetChoice
        ? onChoiceSelected(option.id, presetChoice.id, presetChoice.value)
        : onChoiceSelected(moreSizes.optionId, moreSizes.id, moreSizes.value),
    ]).then(() => onChoiceSelectedSuccess());
  };

  const handleTileSelect = (optionId, choiceId, choiceValue) => {
    const selectedTile = find(choices, { id: choiceId });
    if (customSizesEnabled && selectedTile.internalName === '_more_sizes') {
      setShowMoreSizes(true);

      return Promise.all([]);
    }

    setShowMoreSizes(false);

    return Promise.all([
      onChoiceSelected(optionId, choiceId, choiceValue),
      updateCustomDimensionsWithPreset(selectedTile),
    ]);
  };

  return (
    <>

      <TileOrDropdownTemplate
        {...props}
        choices={getTileChoices(customSizesEnabled, choices, moreSizes, showMoreSizes)}
        onChoiceSelected={handleTileSelect}
      />
      {
        customSizesEnabled && showMoreSizes
        && (
          <div style={{ marginTop: '20px' }}>
            <CustomSizingDropdowns option={customWidth} onChoiceSelected={handleCustomSizingSelect} />
            <CustomSizingDropdowns option={customHeight} onChoiceSelected={handleCustomSizingSelect} />
          </div>
        )
      }
    </>
  );
};

WithCustomSizing.propTypes = {
  onChoiceSelected: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  attributes: PropTypes.object.isRequired,
  choices: PropTypes.array.isRequired,
  allEnabledChoices: PropTypes.array.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
};

export default ConfiguratorTemplate(WithCustomSizing);
