import React from 'react';
import PropTypes from 'prop-types';
import AlertWarning from '../alerts/AlertWarning';

const WarningMessages = (props) => {
  const { option } = props;
  return (
    <>
      {!!option
        && !!option.messages
        && option.messages.filter((m) => m.messageType.toLowerCase() === 'warning').map(({ message }) => (
          <AlertWarning key={message}>
            {message}
          </AlertWarning>
        ))}
    </>
  );
};
WarningMessages.defaultProps = {
  option: null,
};

WarningMessages.propTypes = {
  option: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string,
      messageType: PropTypes.string,
    })),
  }),
};

export default WarningMessages;
