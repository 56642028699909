import { select, call } from 'redux-saga/effects';
import { getSkuPricing } from '../../api/skuMapperApi';
import { configuratorSelector } from '../../selectors';
import { getPriceData } from './_utils';
import isPreviewProduct from '../../utils/isPreviewProduct';

function* getSOPricing(args) {
  const { skuMap } = args;
  const { sku, skuType } = skuMap;
  if (!!sku && !!skuType) {
    const { productInfo } = yield select(configuratorSelector);

    const { productId } = productInfo;

    const skuPrice = yield call(getSkuPricing, sku, skuType, productId);
    if (!skuPrice && isPreviewProduct) {
      // showing that msrp is not setting correctly for NaN for price
      return getPriceData('n/a', 'n/a');
    }
    return getPriceData(skuPrice.price, skuPrice.specialPrice);
  }

  return Promise.resolve();
}

export default getSOPricing;
