import {
  THD_PRODUCT_AVAILABILITY_INIT,
  THD_PRODUCT_AVAILABILITY_DEFAULT,
} from '../actions/actionTypes';

const initialState = {
  choiceInit: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case THD_PRODUCT_AVAILABILITY_INIT: {
    return {
      ...state,
      choiceInit: action.choiceInit,
    };
  }
  case THD_PRODUCT_AVAILABILITY_DEFAULT: {
    return {
      ...state,
      choiceInit: false,
    };
  }
  default:
    return state;
  }
};
