import {
  UPDATE_GCC_PRODUCT_DETAILS,
  UPDATE_LINEITEM_GUID,
  APP_MOUNT
} from '../actions/actionTypes';

export const initialState = {
  details: {
    attributes: {},
  },
  lineItemGuid: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_GCC_PRODUCT_DETAILS:
    return { ...state, details: action.productDetails };
  case UPDATE_LINEITEM_GUID:
    return {
      ...state,
      lineItemGuid: action.lineItemGuid
    };
  case APP_MOUNT: {
    return {
      ...initialState,
    };
  }
  default:
    return state;
  }
};
