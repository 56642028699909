import styled, { css } from 'styled-components';

export const TileDiv = styled.div.attrs({ role: 'presentation' })`
  border: 2px solid #ccc;
  border-radius:3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 20px;
  padding: 8px 10px;
  margin: 5px 10px 5px 0;
  color: #3e7697;
  min-width: 25px;
  cursor: pointer;

  ${(props) => props.selected && css`
    border: 2px solid #f96302;
    background: #fff;
    font-family: helvetica-neue-bold,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #333;
  `}

  ${(props) => props.disabled && css`
    border: 2px solid #ccc;
    position: relative;
    opacity: .6;
    color: #ccc;
    cursor: auto;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left bottom,transparent 48.5%,#ccc 50%,transparent 51.5%);
    }
  `}
`;

export const TileSelectWrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  transition: max-height .5s;
`;
