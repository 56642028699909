import { select } from 'redux-saga/effects';
import {
  actualCartPricingSelector,
  pretendCartPricingSelector,
} from '../../selectors';

function* getLocalOrNationalPricing() {
  const realPricing = yield select(actualCartPricingSelector);

  if (realPricing.originalPrice > 0) {
    return realPricing;
  }

  const pretendPricing = yield select(pretendCartPricingSelector);

  return pretendPricing || realPricing;
}

export default getLocalOrNationalPricing;
