import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
// eslint-disable-next-line no-underscore-dangle
const _getSelectedChoicesForVisualization = (options, choices, visualizedSiteProductOptions) => {
  const filteredChoices = filter(
    choices,
    (choice) => options[choice.optionId].isActive
      && choice.isSelected
      && options[choice.optionId].isVisualized,
  );
  // const res = unionBy(filteredChoices, visualizedSiteProductOptions, 'optionId');
  const newChoices = map(filteredChoices, (choice) => ({
    ...choice,
    visualizedOptionSequence: visualizedSiteProductOptions
      .filter((o) => o.optionId === choice.optionId)[0].order,
  }));
  const sortedChoices = sortBy(newChoices, [(o) => o.visualizedOptionSequence]);
  return sortedChoices;
};

export default _getSelectedChoicesForVisualization;
