import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Checkbox from '../Checkbox';
import {
  BoxChoice,
  ControlWrapper,
  DescriptionLabel,
  ImageWrapper,
  CheckWrapper,
  CheckText,
} from './styles';
import {
  ChoiceImage,
  ChoiceBorder,
} from '../ChoiceBox/styles';

const ChoiceBoxWithControl = ({
  OptionEstimator,
  displayCheckbox,
  selected,
  imageSrc,
  onClick,
  label,
  children,
  ...rest
}) => {
  const choiceBoxContent = (
    <>
      <BoxChoice role="presentation">
        <ImageWrapper>
          <ChoiceImage>
            <img src={imageSrc} alt={label} height="100%" width="100%" />
          </ChoiceImage>
          <ControlWrapper>
            {children}
          </ControlWrapper>
        </ImageWrapper>
      </BoxChoice>
      <DescriptionLabel>
        {label}
      </DescriptionLabel>
      { displayCheckbox && (
        <CheckWrapper>
          <Checkbox checked={selected} />
          <CheckText>Select Item</CheckText>
        </CheckWrapper>
      )}
    </>
  );
  if (OptionEstimator) {
    return (
      <ChoiceBorder onClick={onClick} selected={selected} {...rest}>
        {choiceBoxContent}
      </ChoiceBorder>
    );
  }

  return (
    <ChoiceBorder onClick={onClick} {...rest}>
      {choiceBoxContent}
    </ChoiceBorder>
  );
};

ChoiceBoxWithControl.propTypes = {
  onClick: PropTypes.func,
  imageSrc: PropTypes.string,
  label: PropTypes.node,
  selected: PropTypes.bool,
  children: PropTypes.node,
  displayCheckbox: PropTypes.bool,
  OptionEstimator: PropTypes.bool,
};

ChoiceBoxWithControl.defaultProps = {
  onClick: noop,
  imageSrc: '',
  label: '',
  selected: false,
  children: null,
  displayCheckbox: false,
  OptionEstimator: false,
};

export default ChoiceBoxWithControl;
