import styled from 'styled-components';
import ChoiceSectionHeaderV2 from '../../../components/ChoiceSectionHeader/v2';
import { ViewAll } from '../../../components/collapsibles';

export const Option = styled.div.attrs({ className: 'option-component' })`

`;

// match the standard ChoiceSectionHeader margin
// (since this template's header is inline)
export const HeaderRow = styled.div`
  margin-bottom: 10px;
`;

// inline so ViewAllRight is on same line
// (each `&` just increases specificity/priority of this rule)
export const InlineChoiceSectionHeader = styled(ChoiceSectionHeaderV2)`
  && {
    display: inline;
  }
`;

// self-explanatory
export const ViewAllRight = styled(ViewAll)`
  float: right;
  padding-right:50px
`;
