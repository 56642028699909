import {
  UPDATE_CONFIG,
  UPDATE_ISCOPY,
  UPDATE_CONFIGURATOR_MODE,
  CHOICE_CHANGED_SUCCESS,
  SET_INITIAL_CONFIG,
  PERSIST_CHOICES_ON_LOAD,
} from './actionTypes';
import { createAction } from '../utils/createAction';

export const setInitialConfig = createAction(SET_INITIAL_CONFIG, 'config');

export const updateConfiguratorMode = createAction(UPDATE_CONFIGURATOR_MODE, 'configuratorMode');

export const updateIsCopy = createAction(UPDATE_ISCOPY, 'isCopy');

export const updateConfigurationId = createAction(UPDATE_CONFIG, 'configurationId');

export const choiceIsReady = createAction(CHOICE_CHANGED_SUCCESS, 'choiceIsReady');

export const persistChoices = createAction(PERSIST_CHOICES_ON_LOAD);