import { PRE_AUTO_SELECT_CHOICES } from '../actionTypes';

const preAutoSelection = (choiceAvailability, choicesBySku) => (dispatch) => new Promise((resolve) => {
  const wait = setTimeout(() => {
    dispatch({
      type: PRE_AUTO_SELECT_CHOICES,
      choiceAvailability,
      choicesBySku,
    });
    clearTimeout(wait);
    resolve('done updating pre auto selection');
  }, 500);
});
export default preAutoSelection;
