/* eslint-disable no-mixed-operators */

const easeInOutQuad = (currentTime, startValue, changeInValue, duration) => {
  let t = currentTime;
  const b = startValue;
  const c = changeInValue;
  const d = duration;

  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t -= 1;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

const vertScrollToElement = (scrollable, element, percent = 0.5, duration = 500) => {
  let to = scrollable.clientHeight * percent;
  to = element.offsetTop - to;
  to += element.clientHeight;

  const start = scrollable.scrollTop;
  const change = to - start;
  const startDate = Date.now();

  const animateScroll = () => {
    const currentDate = Date.now();
    const currentTime = currentDate - startDate;

    const val = easeInOutQuad(currentTime, start, change, duration);
    // eslint-disable-next-line no-param-reassign
    scrollable.scrollTop = val;
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      // eslint-disable-next-line no-param-reassign
      scrollable.scrollTop = to;
    }
  };
  animateScroll();
};

// Horizontally scroll to a specified element in a scrolling element
//
// scrollable - the scrolling element
// element - the element to scroll to
// percent - the percent of the visible portion of the scrollable to scroll the element to
// duration - the scroll animation duration
const horizScrollToElement = (scrollable, element, percent = 0.5, duration = 500) => {
  let to = scrollable.clientWidth * percent;
  to = element.offsetLeft - to;
  to += element.clientWidth;

  const start = scrollable.scrollLeft;
  const change = to - start;
  const startDate = Date.now();

  const animateScroll = () => {
    const currentDate = Date.now();
    const currentTime = currentDate - startDate;

    const val = easeInOutQuad(currentTime, start, change, duration);
    // eslint-disable-next-line no-param-reassign
    scrollable.scrollLeft = val;
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      // eslint-disable-next-line no-param-reassign
      scrollable.scrollLeft = to;
    }
  };
  animateScroll();
};

export { vertScrollToElement, horizScrollToElement };
