import { createSelector } from 'reselect';
import productsForCartSelector from './productsForCartSelector';
import productInfoSelector from './productInfoSelector';

const productForDisplaySelector = createSelector(
  productInfoSelector,
  productsForCartSelector,
  (productInfo, productsForCart) => productsForCart[0] || productInfo.DefaultProduct,
);

export default productForDisplaySelector;
