import axios from 'axios';
import toCamelCase from '../utils/camelCase';
import config from '../config';

const baseUrl = config.eleanorBaseUrl;

const getSiteProductAttributes = (partnerProductId, siteId) => {
  const url = `${baseUrl}/api/v2/products/${partnerProductId}/site/${siteId}`;

  return axios.get(url)
    .then((response) => toCamelCase(response.data));
};

export default getSiteProductAttributes;
