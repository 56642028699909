import makeFractionalInchDropdown from './_makeFractionalInchDropdown';

const sixteenths = [
  (0 / 16),
  (1 / 16),
  (2 / 16),
  (3 / 16),
  (4 / 16),
  (5 / 16),
  (6 / 16),
  (7 / 16),
  (8 / 16),
  (9 / 16),
  (10 / 16),
  (11 / 16),
  (12 / 16),
  (13 / 16),
  (14 / 16),
  (15 / 16),
];

export default makeFractionalInchDropdown(sixteenths);
