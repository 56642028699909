import identity from 'lodash/identity';
import { optionsChoiceMappedByOption } from './mappedOptions';
import {
  PRE_AUTO_SELECT_CHOICES,
} from '../../../actions/actionTypes';

const handlers = {

  [PRE_AUTO_SELECT_CHOICES]: (state, action) => {
    const { choiceAvailability, choicesBySku } = action;
    const { options, choices } = state;
    const [optionsModified, choicesModfied] = optionsChoiceMappedByOption(
      options,
      choices, choiceAvailability, choicesBySku,
    );
    return {
      ...state,
      options: {
        ...state.options,
        ...optionsModified,
      },
      choices: {
        ...state.choices,
        ...choicesModfied,
      },

    };
  },

};
export default (action) => (state) => {
  const reduce = handlers[action.type] || identity;
  return reduce(state, action);
};
