import { createSelector } from 'reselect';
import values from 'lodash/fp/values';
import productInfoNonDefaultSelector from './productInfoNonDefaultSelector';
import { hasBopis, hasLocalStock } from '../../utils/getFulfillmentOption';

const productsWithNoStockSelector = createSelector(
  productInfoNonDefaultSelector,
  (productInfo) => {
    const products = values(productInfo);

    const noStockProducts = products.reduce((arr, product) => {
      if (hasBopis(product) && !hasLocalStock(product)) {
        arr.push(product);
      }
      return arr;
    }, []);

    return noStockProducts;
  },
);

export default productsWithNoStockSelector;
