/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ChoiceSectionHeaderV2 from '../../../components/ChoiceSectionHeader/v2';
import SixteenthInchDropdown from '../../../components/SixteenthInchDropdown';
import ConfiguratorTemplate from '../ConfiguratorTemplate';

const EighthInchDropdownWrapper = styled.div`
  margin: -10px -10px 0px -10px;
  @media (max-width: 767px) {
    div[class^="styles__Wrapper"] {
      min-width: 50%;
      box-sizing: border-box;
    }
  }
`;

const CustomSizingDropdowns = ({
  choices: [choice],
  option,
  onChoiceSelected,
  isSixteeninch = false,
  heading,
}) => {
  const handleChange = useCallback((inch, eighth) => {
    const choiceValue = inch + eighth;
    onChoiceSelected(option.id, choice.id, choiceValue);
  }, [option, choice, onChoiceSelected]);

  useEffect(() => {
    if (!choice.isSelected) handleChange(choice.value || choice.minValue, 0);
  }, [choice.isSelected]);

  return (
    <>
      <ChoiceSectionHeaderV2 label={!isSixteeninch ? (option && option.shortLabel) : heading} />

      {/* Styling to adjust left and right margins */}
      <EighthInchDropdownWrapper>
        <SixteenthInchDropdown
          value={choice.value}
          minValue={Math.floor(choice.minValue)}
          maxValue={Math.floor(choice.maxValue)}
          onChange={handleChange}
        />
      </EighthInchDropdownWrapper>

    </>
  );
};

CustomSizingDropdowns.propTypes = {
  onChoiceSelected: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  choices: PropTypes.array.isRequired,
  isSixteeninch: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
};

export default ConfiguratorTemplate(CustomSizingDropdowns);
