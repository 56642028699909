import React from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import identity from 'lodash/identity';
import NativeSelect from './nativeSelect';

const RenderNative = ({
  value,
  options,
  onChange,
  nativeRenderer,
  keyBy,
  disabled,
}) => (
  <NativeSelect
    value={value}
    options={values(options)}
    onOptionSelect={onChange}
    renderer={nativeRenderer}
    keyBy={keyBy}
    disabled={disabled}
  />
);

RenderNative.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  // option => option.keyProp
  keyBy: PropTypes.func,
  nativeRenderer: PropTypes.func,
  disabled: PropTypes.bool,
};

RenderNative.defaultProps = {
  value: undefined,
  keyBy: identity,
  nativeRenderer: null,
  onChange: noop,
  disabled: false,
};

export default RenderNative;
