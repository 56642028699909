import { SET_IS_APP_LOAD_ANALYTICS_DONE } from '../actions/actionTypes';

const initialState = {
  isAppLoadAnalyticsDone: false,
};

export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_IS_APP_LOAD_ANALYTICS_DONE:
  {
    const { isAppLoadAnalyticsDone } = action;
    return {
      ...state,
      isAppLoadAnalyticsDone,
    };
  }
  default:
    return state;
  }
};
