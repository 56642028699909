import React from 'react';
import PropTypes from 'prop-types';
import ThdSelect from '../ThdSelect';
import { Container, Wrapper } from './styles';

const DoubleDropdowns = (props) => {
  const { primary, secondary, onChange } = props;
  return (
    <Container>
      <Wrapper>
        {primary.extras}
        <ThdSelect {...primary} onChange={(option) => onChange(option, secondary.value)} />
      </Wrapper>
      <Wrapper>
        {secondary.extras}
        <ThdSelect
          {...secondary}
          disabled={secondary.options.length <= 1 || secondary.disabled}
          onChange={(option) => onChange(primary.value, option)}
        />
      </Wrapper>
    </Container>
  );
};

const dropdownPropsShape = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  renderer: PropTypes.func,
  optionRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  keyBy: PropTypes.func,
  disabled: PropTypes.bool,
  extras: PropTypes.node,
});

DoubleDropdowns.propTypes = {
  primary: dropdownPropsShape.isRequired,
  secondary: dropdownPropsShape.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DoubleDropdowns;
