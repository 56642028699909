/* eslint-disable react-hooks/rules-of-hooks */
import find from 'lodash/find';
import map from 'lodash/map';
import merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import { useState } from 'react';
import useNucleusData from '../hooks/useNucleusData';
import useProductGraphData from '../hooks/useProductGraphData';
import { findOmsId } from './getOmsId';
import { mapskus } from '../actions/fulfillmentActions/getItemsBySkuMap';
import getConfiguratorSettings from './getConfiguratorSettings';
import toCartFulfillmentMethod from '../actions/cartActions/__toCartFulfillmentMethod';
import { addSpecialOrderToCart } from '../actions/cartActions/addSpecialOrderToCart';
import { mergeFulfillmentOptions } from './mergeExtensions';
import store from '../store/store';

export const getAnchorSku = (data = {}, itemId) => {
  return data?.product?.info?.gccExperienceOmsId || itemId;
};

export const flipPip = (
  configuredProductLabel,
  productData,
  hasPipFlip,
  productId,
  setHasPipFlip,
  itemId,
  anchorSku,
  setItemId
) => {
  const product = {
    configuredProductLabel,
  };
  const stockProduct = {
    configuredProductLabel: productData?.product?.identifiers?.productLabel,
  };
  if (hasPipFlip) {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_choice_selected_event', {
      product: stockProduct,
    });
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_sticky_nav_event', {
      product: stockProduct,
    });
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_media_gallery_event', { extMedia: {} });
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.stock_product_chosen_event', {
      configuredItemId: productId,
    });
  } else if (!hasPipFlip) {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_choice_selected_event', product);
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_sticky_nav_event', product);
  }
  setHasPipFlip(!hasPipFlip);
  return itemId === anchorSku ? setItemId(productId) : setItemId(anchorSku);
};

export const shouldDisplayConfigurator = (data = {}, isServer) => {
  return !isServer && data?.product?.identifiers?.productType === 'CONFIGURABLE_BLINDS';
};

export const validatePipFlip = (data = {}, flag) => {
  return !flag && data?.product?.info?.gccExperienceOmsId;
};

export const customATC = async (cartOptions, fromPaypal) => {
  return store.dispatch(addSpecialOrderToCart(cartOptions, fromPaypal));
};

export const getFulfillmentLocation = ({ method, fulfillmentMethod, thdStoreInfo }) => {
  const fulfillmentLocation = method === 'ShipToStore'
    || method === 'BOPIS'
    || fulfillmentMethod === 'ShipToStore'
    || fulfillmentMethod === 'BOPIS'
    ? thdStoreInfo?.storeNumber
    : thdStoreInfo?.zipcode;

  return fulfillmentLocation;
};

export const getItemDetails = ({
  method,
  fulfillmentMethod,
  thdStoreInfo,
  cart,
  storeNumber,
  qty,
  productInfo,
}) => {
  const fulfillmentLocation = getFulfillmentLocation({ method, fulfillmentMethod, thdStoreInfo });

  let itemDetails = [];

  if (cart && cart?.length <= 0) {
    itemDetails.push({
      storeId: storeNumber,
      zipCode: thdStoreInfo?.zipcode,
      fulfillmentMethod: method || fulfillmentMethod,
      fulfillmentLocation,
      quantity: 1,
    });
  } else {
    itemDetails = map(cart, (x) => {
      if ((qty && qty !== itemDetails?.[0]?.quantity) || qty !== cart?.[0]?.quantity) {
        const item = find(productInfo, (p) => p.itemId === x?.itemId);
        const amountAvailable = item?.storeInfo?.local?.inventory?.onHandQuantity;
        if (amountAvailable <= qty) {
          return {
            itemId: x.itemId,
            storeId: storeNumber,
            zipCode: thdStoreInfo?.zipcode,
            quantity: qty || x.quantity,
            fulfillmentMethod: method || fulfillmentMethod,
            fulfillmentLocation,
          };
        }
      }
      return x;
    });
  }

  return itemDetails;
};

export const getProductConfiguratorData = ({
  data,
  cartProduct,
  itemDetails,
  items,
  fulfillment,
  deliveryTime,
}) => {
  // CODE SMELL: molasses but req'd so fulfillmentOptions get set correctly
  // In some situations previous omsId fulfillment is leaking into current omsId
  const newData = _mergeWith(
    {},
    { product: data?.product || {} },
    { product: cartProduct },
    (obj, src) => mergeFulfillmentOptions(obj, src)
  );

  const newPropProductData = {
    product: {
      configuration: {
        deliveryTime,
        cart: itemDetails,
        items,
      },
    },
  };

  const parsedData = merge({}, { ...newData }, { ...newPropProductData });

  const newPropDeliveryData = {
    product: {
      fulfillment,
    },
  };

  const parsedDeliveryData = merge({}, { ...newData }, { ...newPropDeliveryData });

  return { newData, parsedData, parsedDeliveryData };
};

export const getFulfillmentMethod = ({ fulfillment }) => {
  const type = fulfillment?.fulfillmentOptions?.[0]?.services?.[0]?.type;
  const fulfillmentMethod = toCartFulfillmentMethod(type);

  return { fulfillmentMethod, type };
};

export const getProductConfiguratorProperties = (props) => {
  const {
    cartProduct,
    productInfo,
    config,
    skuMap,
    thdStoreInfo,
    onLoadSuccess,
    onMount,
    onSaveConfiguration,
    afterRulesExecuted,
    afterChoiceSelectionUpdate,
    sku,
    skuType,
    bypassSkuMap,
    itemIdForDisplay,
    attributes,
    updateProductsGraphData,
  } = props;

  const [isMajorAppliance, setIsMajorAppliance] = useState(true);
  const [zipCode, setZipCode] = useState(thdStoreInfo.zipCode);

  const mainImageUrl = cartProduct?.media?.images?.[0]?.primaryLink;
  const items = mapskus(productInfo, config, skuMap, cartProduct?.fulfillment);
  const itemId = items.find((item) => item.isAnchor)?.itemId || config.partnerProductId;
  const storeId = thdStoreInfo.storeNumber;
  const omsId = findOmsId();
  const isPipFlip = omsId !== itemId;

  const configuratorSettings = getConfiguratorSettings(
    onLoadSuccess,
    onMount,
    config,
    thdStoreInfo.storeNumber,
    onSaveConfiguration,
    afterRulesExecuted,
    afterChoiceSelectionUpdate,
    mainImageUrl,
    sku,
    skuType,
    bypassSkuMap,
    isPipFlip,
    omsId
  );

  const { data } = useNucleusData({
    itemId,
    storeId,
    skuMap,
    itemIdForDisplay,
    zipCode,
  });

  useProductGraphData({
    skuMap,
    itemId,
    storeId,
    attributes,
    updateProductsGraphData,
  });

  if (!skuMap.isSpecialOrder) {
    delete cartProduct?.configuration?.cart?.[0]?.configurationId;
  }

  const { fulfillment = {} } = data?.product || {};
  const { fulfillmentMethod, type } = getFulfillmentMethod({ fulfillment });
  const { hiddenOptions } = attributes || {};
  const showOptions = hiddenOptions !== 'True';

  return {
    mainImageUrl,
    items,
    itemId,
    storeId,
    omsId,
    isPipFlip,
    zipCode,
    setZipCode,
    configuratorSettings,
    data,
    fulfillment,
    type,
    fulfillmentMethod,
    isMajorAppliance,
    setIsMajorAppliance,
    showOptions,
  };
};
