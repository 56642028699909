/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const ThdSelectInput = styled.div`
  width: 100%;
  text-align: left;
  ${(props) => props.keepHidden && 'display: none'};
`;

export const ContentWrapper = styled.div.attrs((props) => ({
  className: props.isOpen ? 'drop-down__content-wrapper--open' : 'drop-down__content-wrapper',
}))`
  ${(props) => props.keepHidden && 'display: none'};
`;

export const SpanWrapper = styled.span.attrs({
  className: 'customLabel drop-down__title',
})`
  &&& {
    ${(props) => props.disabled && css`background: #ebebe4;`};
  }
`;
