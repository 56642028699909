import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import initSagas from './initSagas';

// =========================================================
// Saga error handling to catch any unhandled errors in Saga
// =========================================================
// eslint-disable-next-line no-console
const onError = (error) => console.log('Uncaught Global Saga Error:', error);

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const sagaMiddleware = createSagaMiddleware({
  onError,
});

const middlewares = [sagaMiddleware, thunk];

const configureStore = (initialState = {}) => {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  initSagas(sagaMiddleware);

  return store;
};

export default configureStore;