import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import camelCase from 'lodash/camelCase';
import flow from 'lodash/flow';
import ThdSelect from '../../../components/ThdSelect';
import WarningMessage from '../../../components/WarningMessage';
import ChoiceSectionHeaderV2 from '../../../components/ChoiceSectionHeader/v2';
import { OptionErrorMessages } from '../../../components/ErrorMessage';
import ConfiguratorTemplate from '../ConfiguratorTemplate';

class DropdownTemplate extends React.Component {
  getDefinition() {
    const { attributes, option } = this.props;
    return attributes[camelCase(option.shortLabel)];
  }

  getSelectedChoice() {
    const { choices } = this.props;
    return find(choices, 'isSelected');
  }

  choiceChange = ({ id: choiceId, shortLabel: choiceValue }) => {
    const selectedChoice = this.getSelectedChoice();
    if (!selectedChoice || (selectedChoice && choiceId !== selectedChoice.id)) {
      const { option, onChoiceSelected, onChoiceSelectedSuccess } = this.props;
      onChoiceSelected(option.id, choiceId, choiceValue).then(() => onChoiceSelectedSuccess());
    }
  };

  render() {
    const selectedChoice = this.getSelectedChoice();
    const { option, choices } = this.props;

    return (
      <div style={{ marginBottom: '20px' }}>
        <ChoiceSectionHeaderV2
          label={option && option.shortLabel}
          value={selectedChoice && selectedChoice.value}
          definitionHeader={option.shortLabel}
          definitionMarkdown={this.getDefinition()}
        />

        <OptionErrorMessages option={option} />

        <ThdSelect
          options={choices}
          value={selectedChoice}
          keyBy={(choice) => choice.id}
          // eslint-disable-next-line no-confusing-arrow
          renderer={({ value }) => (isEmpty(value)) ? '' : value.shortLabel}
          onChange={this.choiceChange}
          indexable={option.isIndexable && option.isRequired}
        />
        <WarningMessage option={option} />
      </div>
    );
  }
}

DropdownTemplate.propTypes = {
  choices: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  onChoiceSelected: PropTypes.func.isRequired,
  onChoiceSelectedSuccess: PropTypes.func.isRequired,
  attributes: PropTypes.object.isRequired, // attributes is treated like a dictionary
};

/* eslint-disable function-paren-newline */
export default flow(
  // connect to redux
  ConfiguratorTemplate,
)(DropdownTemplate);
