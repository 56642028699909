import styled from 'styled-components';

export const BoxChoice = styled.div`
  padding-top:7%;
  margin-left: 41px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-item: center;
`;

export const DescriptionLabel = styled.div`
  text-align: center;
  padding-top:3%;
  padding-bottom:6%;
`;

export const ControlWrapper = styled.div`
  display: flex;
  align-items:center;
  padding: 20px;
`;

export const CheckText = styled.p`
  height: auto;
  width: 100%;
  display: flex;
  margin-left: 10px;
  font-size: 15px;
`;

export const CheckWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin: 10px 0px 10px 10px;
  padding: 4px;
`;
