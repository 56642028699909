import React from 'react';
import PropTypes from 'prop-types';
import { SpanWrapper } from './styles';

const Value = ({ children, disabled }) => (
  <SpanWrapper disabled={disabled}>
    <span className="custom select">{children}</span>
  </SpanWrapper>
);

Value.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  disabled: PropTypes.bool,
};

Value.defaultProps = {
  disabled: false,
};

export default Value;
