import {
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  skuAvailability: [],
  choiceAvailability: [],
  skusByChoiceId: [],
  choicesBySku: [],
  skusByOptionIdAndChoiceId: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
  case THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS: {
    return {
      ...state,
      skuAvailability: action.skuAvailability,
      choiceAvailability: action.choiceAvailability,
      skusByChoiceId: action.skusByChoiceId,
      choicesBySku: action.choicesBySku,
      skusByOptionIdAndChoiceId: action.skusByOptionIdAndChoiceId,
    };
  }
  default:
    return state;
  }
};
