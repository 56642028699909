import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import some from 'lodash/some';
import values from 'lodash/values';
import productInfoNonDefaultSelector from './productInfo/productInfoNonDefaultSelector';
import { skusSelector } from './skuMap/skusSelector';
import getStorePricing from '../utils/getLocalOrNationalPricing/_getStorePricing';
import getPricingForProducts from '../utils/getLocalOrNationalPricing/_getPricingForProducts';
import { findMultiplier } from './utils';

const withMultiplier = (skus) => (product) => ({
  ...product,
  multiplier: findMultiplier(skus, product.itemId),
});

const pretendCartPricingSelector = createSelector(
  productInfoNonDefaultSelector,
  skusSelector,
  (productInfo, skus) => {
    const allProducts = values(productInfo);

    const productPrices = map(allProducts, getStorePricing);

    // Only continue if we have pricing for everything
    if (some(productPrices, isNil)) {
      return null;
    }

    const multiply = withMultiplier(skus);

    const prods = allProducts.map(multiply);
    const [displayProd] = prods;

    return getPricingForProducts(prods, displayProd);
  },
);

export default pretendCartPricingSelector;
