import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import DoubleDropdowns from './DoubleDropdowns';
import decimalToFraction from '../utils/decimalToFraction';
import ChoiceSectionHeaderV2 from '../components/ChoiceSectionHeader/v2';

const InchRenderer = ({ value }) => value;
const FractionRenderer = ({ value }) => decimalToFraction(value) || 'oops';

const makeFractionalInchDropdown = (options) => {
  const FractionalInchDropdown = ({
    value,
    minValue,
    maxValue,
    onChange,
    whole,
    fractional,
  }) => {
    const handleChange = useCallback((primary, secondary) => {
      const inch = Number(primary);
      let eighth = Number(secondary);
      if (inch + eighth > maxValue) {
        eighth = maxValue - inch;
      } else if (inch + eighth < minValue) {
        eighth = minValue - inch;
      }

      onChange(inch, eighth);
    }, [onChange]);

    const inches = useMemo(
      () => range(minValue, maxValue + 1),
      [minValue, maxValue],
    );

    const primaryValue = Math.floor(value);
    const secondaryValue = value - primaryValue;

    const maxSelected = primaryValue === maxValue;

    const {label: wholeLabel, value: wholeValue } = whole || {};
    const {label: fractionalLabel, value: fractionalValue } = fractional || {};

    let primaryExtras = null;
    let secondaryExtras = null;

    if(wholeLabel) {
      primaryExtras = <ChoiceSectionHeaderV2 label={wholeLabel}
      value={isNaN(wholeValue) ? minValue : wholeValue} />
    }

    if(fractionalLabel) {
      secondaryExtras = secondaryExtras = <ChoiceSectionHeaderV2 label={fractionalLabel}
      value={FractionRenderer({value: isNaN(fractionalValue) ? options[0] : fractionalValue})} />
    }

    return (
      <DoubleDropdowns
        primary={{
          options: inches,
          value: primaryValue,
          renderer: InchRenderer,
          nativeRenderer: InchRenderer,
          extras: primaryExtras
        }}
        secondary={{
          options,
          value: secondaryValue,
          renderer: FractionRenderer,
          nativeRenderer: FractionRenderer,
          disabled: maxSelected,
          extras: secondaryExtras
        }}
        onChange={handleChange}
      />
    );
  };

  FractionalInchDropdown.propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    minValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    maxValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    whole: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    fractional: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  };

  return FractionalInchDropdown;
};

export default makeFractionalInchDropdown;
