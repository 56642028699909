import get from 'lodash/get';

export const productLeveLMap = ['primary', 'secondary'];
const BODFS = 'deliverFromStore';
const BOPIS = 'buyOnlinePickupInStore';
const BOSS = 'buyOnlineShipToStore';
const STH = 'shipToHome';

const checkStore = (store, fulfillmentOption) => get(store, `fulfillmentOptions.${fulfillmentOption}.status`);

const hasFulfillmentOption = (fulfillmentOption) => (product) => {
  const { local, national } = product.storeInfo;
  return (
    checkStore(local, fulfillmentOption)
    || checkStore(national, fulfillmentOption)
  );
};

export const hasLocalStock = (product) => get(product, 'storeInfo.local.inventory.expectedQuantityAvailable');

const hasStockAndFulfillmentOption = (fulfillmentOption) => (product) => hasFulfillmentOption(fulfillmentOption)(product) && hasLocalStock(product);

export const hasBodfs = hasFulfillmentOption(BODFS);
export const hasBopis = hasStockAndFulfillmentOption(BOPIS);
export const hasBoss = hasFulfillmentOption(BOSS);
export const hasSth = hasFulfillmentOption(STH);

const getFulfillmentOption = (product) => {
  if (hasBodfs(product)) {
    return BODFS;
  }
  if (hasBopis(product)) {
    return BOPIS;
  }
  if (hasBoss(product)) {
    return BOSS;
  }
  if (hasSth(product)) {
    return STH;
  }
  return undefined;
};

export default getFulfillmentOption;
