const lookup = {
  [(0 / 16)]: '0/0',
  [(1 / 16)]: '1/16',
  [(2 / 16)]: '1/8',
  [(3 / 16)]: '3/16',

  [(4 / 16)]: '1/4',
  [(5 / 16)]: '5/16',
  [(6 / 16)]: '3/8',
  [(7 / 16)]: '7/16',

  [(8 / 16)]: '1/2',
  [(9 / 16)]: '9/16',
  [(10 / 16)]: '5/8',
  [(11 / 16)]: '11/16',

  [(12 / 16)]: '3/4',
  [(13 / 16)]: '13/16',
  [(14 / 16)]: '7/8',
  [(15 / 16)]: '15/16',
};

const decimalToFraction = (decimal) => lookup[decimal];

export default decimalToFraction;
