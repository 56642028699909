export const thdApiMap = (data, paramStoreId) => {
  const arr = [];
  let defaultPipfigFulfillment = [];
  let isDefault = true;
  if (Array.isArray(data.products)) {
    data.products.forEach((p) => {
      const fulfillmentData = () => {
        let storeId;
        let isAnchor;
        let quantity;
        let quantityFromVirtual;
        const fulfillmentOption = {};
        const pipfigFulfillment = [];
        const pipfigShipping = {};
        const pricing = {
          alternatePricingDisplay: p.pricing.alternatePriceDisplay,
          dollarOff: p.pricing.promotion?.dollarOff || 0,
          originalPrice: p.pricing.original || p.pricing.value,
          percentageOff: p.pricing.promotion?.percentageOff || 0,
          savingsCenter: p.pricing.promotion?.savingsCenter || 'Special Buys',
          specialPrice: p.pricing.value,
          uom: p.pricing.unitOfMeasure,
        };
        const storeStatus = p.fulfillment.anchorStoreStatus;
        const storeStatusType = p.fulfillment.anchorStoreStatusType;
        if (!p.fulfillment.fulfillmentOptions && p.pricing) {
          pipfigFulfillment.push({
            fulfillmentOptions: null,
            pricing,
            storeId,
            storeStatus,
            storeStatusType,
          });
          isDefault = false;
          return { pipfigFulfillment };
        }
        if (p.fulfillment.fulfillmentOptions) {
          p.fulfillment.fulfillmentOptions.forEach((f) => {
            f.services.forEach((s) => {
              if (s.type !== 'express delivery') {
                const end = `${[s.type]}EstimatedShippingEndDate`;
                const begin = `${[s.type]}EstimatedShippingStartDate`;
                pipfigShipping[end] = s.deliveryDates?.endDate;
                pipfigShipping[begin] = s.deliveryDates?.startDate;
              }
              pipfigShipping.eligibleForFreeShipping = s.hasFreeShipping;
              pipfigShipping.excludedShipStates = p.fulfillment.excludedShipStates;
              pipfigShipping.freeShippingThreshold = s.freeDeliveryThreshold;
              pipfigShipping.isDynamicEta = s.dynamicEta || false;

              s.locations.find((l) => {
                isAnchor = l?.isAnchor;
                if (isAnchor) {
                  quantity = l.inventory?.quantity;
                  storeId = Number(l.locationId);
                }
                if (s.type === 'sth' || s.type === 'boss') {
                  quantityFromVirtual = l.inventory?.quantity;
                }
                return null;
              });
              let statusKey;
              if (s.type === 'boss') {
                statusKey = 'buyOnlineShipToStore';
              } else if (s.type === 'bopis') {
                statusKey = 'buyOnlinePickupInStore';
              } else if (s.type === 'sth') {
                statusKey = 'shipToHome';
              } else if (s.type === 'express delivery') {
                statusKey = 'deliverFromStore';
              }
              fulfillmentOption[statusKey] = { status: true };
              fulfillmentOption.fulfillable = f?.fulfillable;
              if (statusKey === 'deliverFromStore') {
                fulfillmentOption[statusKey].assemblyEligible = p.fulfillment.bodfsAssemblyEligible;
                fulfillmentOption[statusKey].deliveryDateAvailability = s.deliveryTimeline.toUpperCase();
                fulfillmentOption[statusKey].estDeliveryCharge = Number(s?.deliveryCharge) || Number(s?.totalCharge);
              }
            });
          });

          const obj = {
            fulfillmentOptions: fulfillmentOption,
            inventory: {
              expectedQuantityAvailable: quantity,
              onHandQuantity: quantity,
            },
            pricing,
            storeId: storeId || Number(paramStoreId),
            storeStatus,
            storeStatusType,
          };
          pipfigFulfillment.push(obj);
          if (fulfillmentOption.buyOnlineShipToStore || fulfillmentOption.shipToHome) {
            pipfigFulfillment.push({
              fulfillmentOptions: {
                fulfillable: true,
              },
              storeId: 8119,
              inventory: {
                expectedQuantityAvailable: quantityFromVirtual,
                onHandQuantity: quantityFromVirtual,
              },
              storeStatusType: 'ACTIVE',
              storeStatus: true,
              pricing,
            });
          }

          isDefault = false;
          defaultPipfigFulfillment = pipfigFulfillment.map((a) => ({ ...a }));
          return { pipfigFulfillment, pipfigShipping };
        }
        isDefault = true;
        delete defaultPipfigFulfillment.fulfillmentOptions;
        defaultPipfigFulfillment.forEach((i) => {
          const tempData = i;
          delete tempData.fulfillmentOptions;
          tempData.fulfillmentOptions = { fulfillable: p.availabilityType.status };
        });
        return { defaultPipfigFulfillment };
      };

      const imageUrls = () => {
        const { images, video } = p.media;
        const pipfigMedia = [];
        if (images || video) {
          images.forEach((img) => {
            img.sizes.forEach((s) => pipfigMedia.push({
              height: Number(s),
              width: Number(s),
              location: img.url.replace(/<SIZE>/i, s),
              mediaType: img.type,
            })
            );
          });
          video.forEach((vid) => {
            pipfigMedia.push(JSON.parse(JSON.stringify(vid).replace(/type/i, 'mediaType')));
          });
        }

        return pipfigMedia;
      };

      arr.push({
        defaultSku: Number(p.itemId),
        productId: Number(p.identifiers.parentId) || Number(p.itemId),
        superSku: p.identifiers.isSuperSku,
        skus: {
          sku: {
            itemId: Number(p.itemId),
            itemType: p.identifiers.productType,
            availabilityType: p.availabilityType.type,
            canonicalUrl: p.identifiers.canonicalUrl,
            partNumber: Number(p.identifiers.itemId),
            info: {
              backOrderFlag: p.fulfillment.backordered || null,
              badges: {},
              brandName: p.identifiers.brandName,
              buyOnlinePickupInStoreEligible: p.fulfillment.buyOnlinePickupInStoreEligible || false,
              buyOnlineShipToStoreEligible: p.fulfillment.buyOnlineShipToStoreEligible || false,
              customExperience: 'custom-storm-doors',
              description: p.details.description,
              modelNumber: Number(p.identifiers.modelNumber),
              omsThdSku: Number(p.identifiers.omsThdSku),
              onlineStatus: p.fulfillment.onlineStoreStatus,
              productClass: Number(p.info.classNumber),
              productDepartment: Number(p.info.productDepartment),
              productLabel: p.identifiers.productLabel,
              productSubClass: Number(p.info.subClassNumber),
              savingsCenter: p.pricing.promotion?.savingsCenter,
              hasServiceAddOns: p.info.hasServiceAddOns,
            },
            media: {
              mediaEntry: imageUrls(),
            },
            shipping: fulfillmentData().pipfigShipping,
            storeSkus: {
              storeSku: isDefault
                ? fulfillmentData().defaultPipfigFulfillment
                : fulfillmentData().pipfigFulfillment,
            },
          },
        },
      });
    });
  }
  return { products: { product: [...arr] } };
};
