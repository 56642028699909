import {
  UPDATE_CART_PRODUCT_GALLERY,
  GET_GALLERY_WITH_FALLBACKS,
  UPDATE_GALLERY,
  UPDATE_PRODUCT_GALLERY
} from './actionTypes';

export const updateGallery = ({ assets }) => ({
  type: UPDATE_GALLERY,
  assets,
});

export const updateProductGallery = () => ({
  type: UPDATE_PRODUCT_GALLERY
});

export const updateCartProductGallery = (media, harmonyGallery) => ({
  type: UPDATE_CART_PRODUCT_GALLERY,
  media,
  harmonyGallery
});

export const getGalleryWithFallbacks = () => ({
  type: GET_GALLERY_WITH_FALLBACKS
});

const getGallery = () => (dispatch) => {
  // handled by gallerySaga
  return Promise.resolve(dispatch(getGalleryWithFallbacks()));
};

export default getGallery;
