import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div`
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;

  &:before, &::after {
    position: absolute;
    pointer-events: none;
    content: "";
    box-sizing: border-box;
    top: 50%;
    left: 50%;
  }
  &::before {
    border-color: rgb(153, 153, 153);
    border-width: 2px;
    border-style: solid;
    width: 20px;
    height: 20px;
    transform: translate(-50%,-50%);
  }
  &[aria-checked=true]::after {
    border-style: solid;
    border-width: 0 3px 3px 0;
    width: 7px;
    height: 14px;
    top: 50%;
    left: 50%;
    color: white;
    transform: translateY(-65%) translateX(-50%) rotate(45deg);
  }

  &>input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
    margin: 0;
  }
  &[aria-checked=true]::before {
    background-color: rgb(249, 99, 2) !important;
    border-width: 0 3px 0px 0;
    border-style: solid;
    border-color: rgb(249, 99, 2);
    border-image: initial;
  }
`;
