import { createSelector } from 'reselect';
import compact from 'lodash/compact';
import values from 'lodash/values';
import productInfoSelector from './productInfoSelector';
import getFulfillmentOption, { hasBopis, hasBoss, productLeveLMap } from '../../utils/getFulfillmentOption';
import { productSelector } from '../product/productSelector';

const productsHaveBopis = (productInfo) => {
  const availableSubProducts = Object.keys(productInfo).filter((product) => productLeveLMap.find((productName) => product === productName)
  );

  const productsData = availableSubProducts.map(
    (productName) => productInfo[productName]
  );

  let bopisIsAvailable = true;

  productsData.forEach((productData) => {
    const bopisStatus = !!hasBopis(productData);
    if (!bopisStatus) {
      bopisIsAvailable = false;
    }
  });

  return bopisIsAvailable;
};

const productsForCartSelector = createSelector(
  [productInfoSelector, productSelector],
  (productInfo, product) => {
    const {
      DefaultProduct, // discard "null" product
      base,
      primary,
      ...rest
    } = productInfo;
    const isMajorAppliance = product?.details?.attributes?.isMajorAppliance;
    // We only check availability of primary, and assume others are available
    // run down of things to check:
    //  - combined BOPIS (available and in stock)
    //  - primary BOPIS (available and in stock)
    //  - combined BOSS
    //  - primary any
    //  - base any
    let products = [];

    // TODO: review this logic, see if it still
    // makes sense with new fulfillment tiles.
    if (base && hasBopis(base)) {
      products = [base];
    } else if (primary && productsHaveBopis(productInfo)) {
      products = [primary, ...values(rest)];
    } else if (base && hasBoss(base)) {
      products = [base];
    } else if (primary && getFulfillmentOption(primary)) {
      products = [primary, ...values(rest)];
    } else if (base && getFulfillmentOption(base)) {
      products = [base];
    } else if (isMajorAppliance) {
      products = [primary, ...values(rest)];
    }

    return compact(products);
  },
);

export default productsForCartSelector;
