import { UPDATE_PAGE_META_LOADING, UPDATE_PAGE_META_OVERVIEW, GET_PAGE_META_OVERVIEW_SUCCESS } from './actionTypes';
import pageMetaViewApi from '../api/pageMetaViewApi';

const getPageMetaOverview = () => (dispatch, getState) => {
  const {
    configurator: {
      productInfo: { productId },
    },
    config: { siteId },
    pageMetaOverview: { pageMeta, loading }
  } = getState();

  if (!pageMeta && !loading) {
    dispatch({
      type: UPDATE_PAGE_META_LOADING,
      loading: true
    });
    pageMetaViewApi(productId, siteId).then((data) => {
      dispatch({
        type: UPDATE_PAGE_META_OVERVIEW,
        pageMeta: data.Meta,
        loading: false
      });
      dispatch({
        type: GET_PAGE_META_OVERVIEW_SUCCESS,
      });
    });
  } else {
    dispatch({
      type: GET_PAGE_META_OVERVIEW_SUCCESS,
    });
  }

};

export default getPageMetaOverview;