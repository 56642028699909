import {
  GET_DELIVERY_OPTIONS_SUCCESS,
  GET_DELIVERY_OPTIONS_FAILURE,
  UPDATE_SO_DELIVERY_TIME,
} from '../actions/actionTypes';

const initialState = { specialOrderDeliveryTime: {}, deliveryOptions: [] };

export default (state = initialState, action) => {
  switch (action.type) {
  case GET_DELIVERY_OPTIONS_SUCCESS: {
    const deliveryOptions = action.deliveryOptions.map((opt) => ({
      name: opt.modeDesc,
      price: opt.totalCharge,
      description: opt.modeLongDesc
        ? opt.modeLongDesc.split('||')
        : [],
    }));
    return { ...state, deliveryOptions };
  }
  case GET_DELIVERY_OPTIONS_FAILURE:
    return { ...state, deliveryOptions: [] };
  case UPDATE_SO_DELIVERY_TIME: {
    const { deliveryTime, productionLeadTime } = action;
    return { ...state, specialOrderDeliveryTime: deliveryTime, productionLeadTime };
  }
  default:
    return state;
  }
};
