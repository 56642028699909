import pickBy from 'lodash/pickBy';
import values from 'lodash/values';

const getChoices = (optionIds, choices) => {
  let result = {};
  optionIds.forEach((option) => {
    const choice = values(pickBy(choices, (a) => a.optionId === option && a.isSelected))[0];
    if (choice) {
      const { value } = choice;
      result = { ...result, [option]: value };
    }
  });
  return result;
};

const validateOptions = (optionIds, options) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const optionId of optionIds) {
    if (!options[optionId]) {
      return false;
    }
  }
  return true;
};
export default (seoString, options, choices) => {
  if (!seoString || seoString.trim() === '') return null;

  const regEx = /optionId:\d+/g;
  const matches = seoString.match(regEx);
  if (!matches) return seoString;

  const numberIndex = matches[0].indexOf(':') + 1;
  const optionIds = matches.map((a) => parseInt(a.substring(numberIndex), 10));
  if (!validateOptions(optionIds, options)) {
    return null;
  }
  const optionChoice = getChoices(optionIds, choices);
  let resultString = seoString;
  let optionValue;
  // eslint-disable-next-line no-restricted-syntax
  for (const op of optionIds) {
    const match = matches.find((a) => a.includes(op));
    optionValue = match !== optionChoice[op] && optionChoice[op] !== undefined ? optionChoice[op] : '';
    resultString = resultString.replace(match, optionValue);
  }

  return resultString;
};
