import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import cookieUtils from 'cookie-utils';
import { hasTagValue } from '../../utils/tags';
import Portal from '../../components/Portal';
import InternalNotificationBanner from '../../components/InternalNotificationBanner';
import { getRenderOptions } from '../../utils/getRenderOptions';

const hasHiddenTag = (option) => hasTagValue(option, 'pipfigurator', 'hidden');
global.cookieUtils = cookieUtils;
const hasPipFlipCookie = cookieUtils.fed_ReadCookie('tnt_pipflip');
const text = (isPipFlip) => (hasPipFlipCookie && isPipFlip ? <span style={{ fontSize: '20px' }}>Create Your Own</span> : 'Choose Your Options');

const Layout = ({
  options, details, isPipFlip, isPreview
}) => {
  if (isEmpty(options.allIds)) {
    return null;
  }
  const renderOptions = getRenderOptions({ options, hasHiddenTag });

  return (
    <div className="buybox" data-section="buybox">
      {(details.isDraft || isPreview) && (
        <Portal node={document.querySelector('.hfapp')}>
          <InternalNotificationBanner details={details} isPreview={isPreview} />
        </Portal>
      )}
      <div className="buybox__super-sku">
        <h2 className="buybox__section-title">{ text(isPipFlip) }</h2>
        <div>{renderOptions}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  options: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  isPipFlip: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
};

Layout.defaultProps = {
};

const mapStateToProps = (state) => {
  const {
    configurator: { options, choices },
    product: { details }
  } = state;
  return {
    options,
    choices,
    details,
  };
};

const options = {
  pure: true,
  areOwnPropsEqual: (prev, next) => isEqual(prev, next),
  areStatePropsEqual: (prev, next) => isEqual(prev, next),
  areMergedPropsEqual: (prev, next) => isEqual(prev, next),
};

export default connect(mapStateToProps, null, null, options)(Layout);
