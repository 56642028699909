import filter from 'lodash/filter';
import includes from 'lodash/includes';

const getSelectedChoices = (options, choices) => {
  const isSkuMapped = (option) => (
    !includes(option.tags, 'notskumapped')
    || includes(option.tags, 'category-quantity')
  );

  return filter(
    choices,
    (choice) => options[choice.optionId].isActive
      && choice.isSelected
      && isSkuMapped(options[choice.optionId]),
  );
};

export default getSelectedChoices;
