import { useDataModel } from '@thd-nucleus/data-sources';
import { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import castArray from 'lodash/castArray';
import _first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import { getMappedSkus } from '../sagas/pricingSaga/_utils';
import toCamelCase from '../utils/camelCase';
import { thdApiMap } from '../api/graphQL/mapping/thdApiMapping';
import omsId from '../utils/getOmsIdFromUrl';
import isDraftProduct from '../utils/isDraftProduct';

const castProductPropertyToArray = (data) => {
  const product = castArray(data.products.product);
  return {
    products: {
      product,
    },
  };
};

const useProductGraphData = ({
  skuMap, itemId, storeId, attributes, updateProductsGraphData
}) => {
  const [productData, setProductData] = useState({
    data: null,
    loading: false,
    error: null,
    itemIds: null,
  });

  let uniqueitemIds;

  if (skuMap.skuType === 'HomeDepot_OMSID') {
    const skuNumbers = getMappedSkus(skuMap);
    uniqueitemIds = uniq(skuNumbers);
  } else {
    uniqueitemIds = [isDraftProduct ? omsId : itemId.toString()];
  }

  let zipCode = '30339';
  if (
    typeof window !== 'undefined'
    && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails
  ) {
    zipCode = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails()?.zipcode;
  }
  let variables = { zipCode, storeId, itemIds: uniqueitemIds };
  let skipFetch = skuMap.isLoading || !skuMap?.isValid;
  const { data, loading, error } = useDataModel('products', {
    variables,
    ssr: false,
    skip: skipFetch,
  });

  if (!skipFetch && !isEqual(uniqueitemIds, productData.itemIds)) {
    setProductData({ loading, itemIds: uniqueitemIds });
  }

  useEffect(() => {
    let currUniqueItemIds;
    if (skuMap.skuType === 'HomeDepot_OMSID') {
      const skuNumbers = getMappedSkus(skuMap);
      currUniqueItemIds = uniq(skuNumbers);
    } else {
      currUniqueItemIds = [isDraftProduct ? omsId : itemId.toString()];
    }

    if (
      data
      && !loading
      && ((!isEqual(data, productData.data) && isEqual(currUniqueItemIds, productData.itemIds))
        || skuMap.skuType === '')
    ) {
      const mappedData = thdApiMap(data);
      const castedData = castProductPropertyToArray(mappedData);
      let thdDetails = toCamelCase(castedData);
      let newSkus = {
        ...skuMap,
      };

      if (skuMap.skuType !== 'HomeDepot_OMSID') {
        newSkus.sku = isDraftProduct ? omsId : itemId.toString();
      }

      if (attributes?.allowDuplicateSubSkus) {
        const thdDetailsWithDuplicates = {
          products: {
            product: skuMap.subSkus.flatMap((s) => thdDetails.products.product.filter((p) => Number(s.sku) === p.defaultSku)
            ),
          },
        };
        thdDetails = thdDetailsWithDuplicates;
      }
      const updatedData = { newSkus, thdDetails };
      updateProductsGraphData(updatedData);
      setProductData({ data, loading: false, error, itemIds: currUniqueItemIds });
    }
  }, [data, loading, error, skuMap, attributes]);
};

export default useProductGraphData;
