/* eslint-disable no-param-reassign */
import { actionTypes } from '@gcc/configurator';
import transform from 'lodash/transform';
import getSkuMap from '../getSkuMap';
import { getPricing } from '../pricingActions';
import { updatePath } from '../pathActions';

const postAutoSelection = (data, oosChoices) => (dispatch, getState) => {
  const {
    configurator: {
      choices,
      options,
      ajaxStatus: { saveAttempts },
    },
  } = getState();
  const disabledChoices = [...data.disabledChoices, ...oosChoices];

  const selectedChoices = data.selectedChoices.map((o) => {
    const isSelectedChoiceinOos = disabledChoices.some((c) => c === o.choiceId);
    if (!isSelectedChoiceinOos) return o;
    return {};
  });

  dispatch({
    type: actionTypes.EXECUTE_RULES_SUCCESS,
    data,
    saveAttempts,
    disabledChoices: transform(disabledChoices, (obj, it) => (obj[it] = choices[it]), {}),
    selectedChoices,
    choices,
    options,
  });
  dispatch({
    type: actionTypes.UPDATE_DISABLED_CHOICES,
    disabledChoices: transform(data.disabledChoices, (obj, it) => (obj[it] = choices[it]), {}),
  });
  dispatch(getSkuMap())
    .then(() => Promise.all([
      dispatch(getPricing()),
      dispatch(updatePath()),

    ]));
};
export default postAutoSelection;
