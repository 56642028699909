import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';
import get from 'lodash/get';
import ONLINE_STORE_ID from '../../utils/onlineStoreId';
import {
  getDefaultStoreDetails,
  getInitialProductState,
  DefaultProduct,
} from './_defaults';

const getStoreLevelDetails = (product) => {
  const isNationalStore = (store) => store.storeId === ONLINE_STORE_ID;

  const detailsByStore = product.skus.sku.storeSkus.storeSku.map((storeSku) => ({
    // thd won't include pricing/inventory properties if no data found for them
    ...getDefaultStoreDetails(),
    ...storeSku,
  }));

  // Will only ever be 1-2 stores per product (1 national, 0-1 local).
  // National store details are always included in response.
  // Local store details are requested if user has a store selected.
  const [[national], [local]] = partition(detailsByStore, isNationalStore);

  return {
    storeInfo: {
      local,
      national,
    },
  };
};

const getInfo = (product, prop) => get(product, `skus.sku.info.${prop}`);

const getProductLevelDetails = (product) => {
  const savingsCenter = getInfo(product, 'savingsCenter');

  return {
    itemId: product.skus.sku.itemId,
    isAppliance: product.skus.sku.info.isAppliance,
    source: 'thd',
    productName: getInfo(product, 'productLabel'),
    productBrand: getInfo(product, 'brandName'),
    storeSkuNumber: getInfo(product, 'storeSkuNumber'),
    modelNumber: getInfo(product, 'modelNumber'),
    isSpecialBuy: savingsCenter === 'Special Buys',
    isLowerPrice: savingsCenter === 'New Lower Prices',
    shipping: product.skus.sku.shipping || {}, // just product-level (not store-level)
    media: product.skus.sku.media,
    hasServiceAddOns: product.skus.sku.info.hasServiceAddOns || false,
  };
};

const getRelevantProductDetails = (product) => ({
  ...getInitialProductState(),
  ...getStoreLevelDetails(product),
  ...getProductLevelDetails(product),
});

const getSkuPrecedence = (newSkus, itemId, itemsCased) => {
  if (newSkus?.sku === itemId) {
    return 'base';
  }
  const duplicateSubSkus = newSkus?.subSkus.filter((sub) => (sub.sku === itemId) && (!itemsCased.includes(sub.precedence)));
  const subSku = duplicateSubSkus?.find((sub) => sub.sku === itemId);
  // eslint-disable-next-line no-unused-expressions
  itemsCased?.push(subSku?.precedence);
  switch (subSku?.precedence) {
  case 1: return 'primary';
  case 2: return 'secondary';
  default: return `subProduct${subSku?.precedence}`;
  }
};

export default (state, { newSkus, thdDetails, skuPrice }) => {
  if (thdDetails) {
    const products = thdDetails.products.product.map(getRelevantProductDetails);
    const itemsCased = [];

    // key relevant product details by product.itemId
    const productDetailsBySku = keyBy(
      products,
      (product) => getSkuPrecedence(newSkus, product.itemId.toString(), itemsCased),
    );

    // we always want to include DefaultProduct as a "null" object
    return {
      DefaultProduct,
      ...productDetailsBySku,
    };
  }

  if (skuPrice) {
    return {
      DefaultProduct,
    };
  }

  return state;
};
