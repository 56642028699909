/* eslint-disable quote-props, key-spacing */
const redux2CartApi = {
  'boss':                   'ShipToStore',
  'bopis':                  'BOPIS',
  'express delivery':       'BOPIS',
  'buyOnlineShipToStore':   'ShipToStore',
  'buyOnlinePickupInStore': 'BOPIS',
  'shipToHome':             'ShipToHome',
  'sth':                    'ShipToHome',
  'deliverFromStore':       'DeliverFromStore',
  'directDelivery':         'BOPIS',
  'pickup':                 'BOPIS',
};

export default function toCartFulfillmentMethod(fulfillmentMethod) {
  return redux2CartApi[fulfillmentMethod];
}
