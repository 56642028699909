import React from 'react';
import PropTypes from 'prop-types';
import { Root } from './styles';

/** A simple checkbox component with flexible styling. */
class Checkbox extends React.Component {
  constructor(props) {
    super();

    if (props.checked != null) {
      this.isControlled = true;
      this.state = {};
    } else {
      this.isControlled = false;
      this.state = {
        checked: props.defaultChecked !== undefined ? props.defaultChecked : false,
      };
    }
  }

  handleChange = (event) => {
    const { onChange } = this.props;
    const { checked } = event.target;
    if (!this.isControlled) {
      this.setState({ checked });
    }

    if (onChange) {
      onChange(event, checked);
    }
  };

  render() {
    const {
      autoFocus,
      checked: checkedProp,
      className,
      defaultChecked,
      disabled,
      inputRef,
      id,
      label,
      name,
      onBlur,
      onFocus,
      readOnly,
      required,
      tabIndex = '0',
      type,
      value,
    } = this.props;
    const {
      checked: checkedState,
    } = this.state;
    const checked = this.isControlled ? checkedProp : checkedState;

    if (label && !id) {
      // eslint-disable-next-line no-console
      console.warn('Checkbox: if label is specified, id should also be specified.');
    }

    return (
      <Root
        className={className}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={tabIndex}
        role="checkbox"
        aria-disabled={disabled}
        aria-checked={checked}
        aria-readonly={readOnly}
      >
        <input
          autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={this.handleChange}
          readOnly={readOnly}
          ref={inputRef}
          required={required}
          type={type}
          value={value}
          tabIndex={-1}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </Root>
    );
  }
}

Checkbox.propTypes = {
  /** If true, the input will be focused during the first mount. */
  autoFocus: PropTypes.bool,
  /** If true, the component is checked. */
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Classname for the root component */
  className: PropTypes.string,
  /** Default checked value if this component is uncontrolled */
  defaultChecked: PropTypes.bool,
  /** If true, the input element will be disabled. */
  disabled: PropTypes.bool,
  /** A ref callback to pass to the input component. */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** The id of the input element. */
  id: PropTypes.string,
  /** A label to apply to the checkbox. */
  label: PropTypes.string,
  /** The name of the input element. */
  name: PropTypes.string,
  /** A function to call when the component is blurred. */
  onBlur: PropTypes.func,
  /** A function to call when the checkbox is checked/unchecked. */
  onChange: PropTypes.func,
  /** A function to call when the component is focussed. */
  onFocus: PropTypes.func,
  /** If true, prevents the user from changing the value of the field (not from interacting with it). */
  readOnly: PropTypes.bool,
  /** If true, the input element will be required. */
  required: PropTypes.bool,
  /**  */
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** The type of the input element. */
  type: PropTypes.string,
  /** The value of the inputelement. */
  value: PropTypes.string,
};

Checkbox.defaultProps = {
  autoFocus: false,
  checked: undefined,
  className: '',
  defaultChecked: undefined,
  disabled: false,
  inputRef: undefined,
  id: undefined,
  label: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  tabIndex: undefined,
  type: 'checkbox',
  value: undefined,
  readOnly: false,
  required: undefined,
};

export default Checkbox;
