import {
  UPDATE_CART_PRODUCT_PRICING,
} from './actionTypes';

export const updateHarmonyPrice = (isSpecialBuy, originalPrice, specialPrice) => (dispatch, getState) => {
  const specialBuy = isSpecialBuy ? 'Special Buy' : null;
  const discount = originalPrice - specialPrice;
  const percent = (discount / originalPrice) * 100;

  if (originalPrice && originalPrice > 0) {

    const pricing = {
      unitOfMeasure: 'each',
      value: specialPrice,
      original: originalPrice,
      promotion: {
        dollarOff: discount,
        percentageOff: percent,
        savingsCenter: specialBuy,
        type: 'DISCOUNT'
      }
    };

    return dispatch({
      type: UPDATE_CART_PRODUCT_PRICING,
      data: pricing,
    });
  }
  return null;
};