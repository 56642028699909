import sumBy from 'lodash/sumBy';
import some from 'lodash/some';
import get from 'lodash/get';
import getStorePricing from './_getStorePricing';

const getProductPricing = (product) => {
  const pricing = getStorePricing(product);
  const { itemId, multiplier } = product;

  return {
    itemId,
    pricing: {
      ...pricing,
      originalPrice: pricing.originalPrice * multiplier,
      specialPrice: pricing.specialPrice * multiplier,
      dollarOff: pricing.dollarOff * multiplier,
    },
  };
};

// If the pricings array has more than one object in it, then it
// was built from subSkus and the first object is the pricing for
// the primary product.
const sumOrZeroIfPrimaryZero = (pricings, propertyName) => {
  const priceProp = `pricing.${propertyName}`;
  if (get(pricings[0], priceProp) > 0) {
    return sumBy(pricings, priceProp);
  }
  return 0;
};

const getPricingForProducts = (products, displayProduct) => {
  const baseProduct = getProductPricing(displayProduct);
  const pricingPerProduct = products.map((product) => getProductPricing(product));

  return {
    // product-level
    isSpecialBuy: some(products, 'isSpecialBuy'),
    isLowerPrice: some(products, 'isLowerPrice'),
    products: pricingPerProduct,
    // store-level
    originalPrice: sumOrZeroIfPrimaryZero(pricingPerProduct, 'originalPrice'),
    specialPrice: sumOrZeroIfPrimaryZero(pricingPerProduct, 'specialPrice'),
    uom: baseProduct.uom,
  };
};

export default getPricingForProducts;
