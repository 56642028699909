import axios from 'axios';
import config from '../config';

export const log = (message, level = '') => {
  let url = `${config.customBaseUrl}/pipfigurator/api/log`;
  if (level) {
    url = `${url}/${level}`;
  }

  return axios.post(url, `=${message}`);
};

export const LogLevels = {
  Error: 'Error',
  Warn: 'Warn',
  Trace: 'Trace',
  Info: 'Info',
  Debug: 'Debug',
};
