/* eslint-disable no-plusplus */
import filter from 'lodash/filter';

export const getSelectedChoices = (op, chs) => filter(
  chs,
  (choice) => op[choice.optionId].isActive
      && choice.isSelected,
);

export const updateOptionMessage = (option) => ({
  ...option,
  messages: [],
});

export const selectedChoice = (selectedChoices, id) => {
  let sChoice = [];
  sChoice = selectedChoices.filter((v) => v.optionId === id);
  if (sChoice.length) return sChoice[0].id;
  return sChoice;
};

export const selectedOptionItems = (options) => {
  const optionIds = options.allIds.filter((id) => options[id].isActive && !options[id].isHidden);
  return optionIds;
};

export const sortedChoiceFromSet = (newSet) => {
  const sortedChoice = Array.from(newSet || []).sort((a, b) => a - b);
  return sortedChoice;
};

export const isSelectedChoice = (choice, flag) => ({
  ...choice,
  isSelected: flag,
  isActive: flag,
  isAvailable: flag,
});
export const isActivateChoice = (choice, flag) => ({
  ...choice,
  isActive: flag,
  isAvailable: flag,
});
export const isDisabledChoice = (choice, flag) => ({
  ...choice,
  isActive: !flag,
  isSelected: !flag,
  isAvailable: !flag,
});
export const isActivateSelectionChoice = (choice, flag) => ({
  ...choice,
  isActive: flag,
  isSelected: flag,
});
export const isDefaultChoice = (choice, flag) => ({
  ...choice,
  isDefault: flag,
});
export const isOutOfStockChoice = (choice, flag) => ({
  ...choice,
  isActive: !flag,
  isSelected: !flag,
});
export const intersectionChoiceByOption = (choices, skus, skuOptChoices, currentOption) => {
  const choiceByOption = [];
  for (let i = 0; i < skus.length; i++) {
    const sk = skus[i];
    const validChoices = skuOptChoices[sk][currentOption];
    for (let l = 0; l < validChoices.length; l++) {
      const vChoice = validChoices[l];
      if (choices[vChoice]) choiceByOption.push(vChoice);
    }
  }
  return choiceByOption;
};
