import React from 'react';
import PropTypes from 'prop-types';

const SelectWrapper = ({ children, isActive }) => (
  <div className="sku_variant product_sku_Overlay_ListBoxes u__clearfix">
    <div className={`sku_variant product_sku_Overlay_ListBoxes_2 pip-drop-down__header ${isActive ? 'active' : ''}`}>
      {children}
    </div>
  </div>
);
SelectWrapper.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
};

SelectWrapper.defaultProps = {
  isActive: false,
  children: null,
};

export default SelectWrapper;
