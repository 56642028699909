import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const Wrapper = styled.div`
  min-width: 225px;
  display: inline-block;
  padding: 10px;
`;
