export const mapskus = (productInfo, config, skuMap) => {
  let keys = Object.keys(productInfo);
  let items = [];

  const updateItems = (newKeys, skuName) => {
    newKeys.forEach((key) => {
      items.push({
        itemId: productInfo[key].itemId,
        isAnchor: !!([skuName].includes(key) && productInfo[key].itemId !== config.partnerProductId),
        isBase: !![skuName].includes(key),
      });
    });
  };
  if (skuMap.isSpecialOrder) {

    if (productInfo) {
      if (keys.includes('primary') && Object.keys(productInfo.primary.shipping).length !== 0) {
        const anchorQuantity = productInfo.primary?.storeInfo?.local?.inventory?.onHandQuantity;
        if (anchorQuantity > 0) {
          updateItems(keys, 'primary');
        } else {
          updateItems(keys, 'base');
        }

        return items;
      }

      updateItems(keys);

      const hasAnchor = items.filter((i) => i.isAnchor === true).length;
      if (skuMap.skuType === 'HomeDepot_OMSID' && items?.[0] && hasAnchor < 1) {
        items[0].isAnchor = true;
      }
    }

    return items;
  }

  keys.forEach((key) => {
    items.push({
      itemId: productInfo[key].itemId,
      isAnchor: !!['base', 'primary'].includes(key),
    });
  });

  return items;
};