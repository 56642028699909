import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _values from 'lodash/values';
import toCartFulfillmentMethod from './__toCartFulfillmentMethod';
import { FULFILLMENT_METHOD_BOPIS, FULFILLMENT_METHOD_BOSS, FULFILLMENT_METHOD_APPLIANCE } from '../hydration/constants';

//
export const cartProducts = (allowDuplicateSubSkus, subSkus, cartData) => {
  if (_isEqual(allowDuplicateSubSkus, 'True')) {
    const skuProducts = _compact(
      _map(subSkus, (s) => _find(cartData, { itemId: Number(s.sku) }))
    );
    return skuProducts;
  }
  return cartData;
};

//
export const createItemDetails = (
  cartProduct,
  items,
  thdStoreInfo,
  subSkus,
  fulfillment,
  productInfoNonDefault,
  configuration,
  isMajorAppliance,
  allowDuplicateSubSkus
) => {
  let fulfillmentType = configuration?.cart?.[0]?.fulfillmentMethod;
  const matchItem = _find(_values(productInfoNonDefault), { itemId: cartProduct.itemId });
  // MajorAppliance fulfillmentType always DirectDelivery from HD
  if (isMajorAppliance) {
    fulfillmentType = FULFILLMENT_METHOD_APPLIANCE;
  } else if (matchItem) {
    const fulfillmentOptions = matchItem.storeInfo?.local?.fulfillmentOptions;
    if (!fulfillmentOptions || fulfillmentOptions.length === 0) {
      fulfillmentType = undefined;
    } else {
      // let's check if current selected fulfillmentType exists in the product, if not default to first available
      const toCartListReturnedFromOptions = Object.keys(fulfillmentOptions).map((option) => toCartFulfillmentMethod(option)
      );
      const fulfillmentMatch = toCartListReturnedFromOptions.find(
        (option) => option === fulfillmentType
      );

      fulfillmentType = fulfillmentMatch || toCartFulfillmentMethod(Object.keys(fulfillmentOptions)?.[0]);
    }
  }

  if (!fulfillmentType) {
    return undefined;
  }

  const choiceQuantity = subSkus.find((x) => Number(x.sku) === cartProduct.itemId);
  const cartQuantity = configuration?.cart?.[0]?.quantity || 1;
  const multipliedQuantity = choiceQuantity ? choiceQuantity.multiplier * cartQuantity : cartQuantity;
  if (choiceQuantity && !isMajorAppliance) {
    const item = _find(
      productInfoNonDefault,
      (p) => p.itemId === cartProduct.itemId
    );
    const amountAvailable = item?.storeInfo?.local?.inventory?.onHandQuantity;
    const anchorSku = items.find((i) => i.isAnchor === true);
    const isSplitFulfillment = anchorSku?.itemId !== item?.itemId;
    let fulfillmentTypes = [];
      // eslint-disable-next-line no-unused-expressions
      fulfillment?.fulfillmentOptions?.forEach(
        (x) => x.services.forEach(
          (i) => fulfillmentTypes.push(i.type)
        )
      );
      const type = fulfillmentTypes.filter((t) => t !== fulfillmentType?.toLowerCase())?.[0];
      if (multipliedQuantity > amountAvailable && isSplitFulfillment) {
        const convertedType = toCartFulfillmentMethod(type);
        fulfillmentType = convertedType;
      }
  }
  const orderLocation = (fulfillmentType === FULFILLMENT_METHOD_BOSS || fulfillmentType === FULFILLMENT_METHOD_BOPIS) ? thdStoreInfo?.storeNumber : thdStoreInfo?.deliveryZip;

  const itemData = {
    itemId: `${cartProduct.itemId}`,
    storeId: thdStoreInfo?.storeNumber,
    zipCode: thdStoreInfo?.zipcode,
    fulfillmentMethod: fulfillmentType,
    fulfillmentLocation: orderLocation,
    quantity: multipliedQuantity,
  };

  if (allowDuplicateSubSkus) {
    itemData.applianceDeliveryStore = cartProduct.storeInfo.local.storeId;
  }

  return itemData;
};
