import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import identity from 'lodash/identity';
import Text from './Text';

export const commonPropTypes = {
  // select options (not configurator options)
  options: PropTypes.array.isRequired,
  // option
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  // option => option.keyProp
  keyBy: PropTypes.func,
  renderer: PropTypes.func,
  optionRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func,
  wrapperStyle: PropTypes.object,
  isWrapperOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  nativeRenderer: PropTypes.func,
  hrefBy: PropTypes.func,
};

export const commonDefaults = {
  value: undefined,
  keyBy: identity,
  renderer: null,
  optionRenderer: Text,
  valueRenderer: Text,
  label: '',
  onChange: noop,
  wrapperStyle: { top: '45px' },
  isWrapperOpen: false,
  disabled: false,
  nativeRenderer: null,
  hrefBy: noop,
};
