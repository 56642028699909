import styled from 'styled-components';

export const MeasureContainer = styled.div`
font-size: 1.4rem;

a {
    color: #3e7697;
}
`;

export const Definition = styled.div`
  padding: 2em;
  text-align: center;

  img {
    display: block;
    margin: auto;
    padding: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  p {
    font-size: 1em;
  }

  h3 + p,
  p + p {
    margin-top: 1em;
  }

  @media (min-width: 992px) {
    max-height: 500px !important;
    overflow: auto;
  }
`;
