// eslint-disable-next-line import/extensions
import 'core-js/modules/web.dom.iterable.js';
import { useEffect, useState } from 'react';

const useLifeCycleEventBus = (eventName) => {
  const [eventPayload, setEventPayload] = useState({});
  useEffect(() => {
    const eventHandler = (payload) => {
      const {
        output
      } = payload;
      setEventPayload(output);
    };

    if (window.LIFE_CYCLE_EVENT_BUS !== undefined) {
      window.LIFE_CYCLE_EVENT_BUS.on(eventName, eventHandler);
    }
  }, [eventName]);
  return eventPayload;
};

export { useLifeCycleEventBus };
// # sourceMappingURL=useLifeCycleEventBus.js.map