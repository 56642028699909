import { takeLatest, select } from 'redux-saga/effects';
import {
  LOG_TO_NEW_RELIC,
} from '../../actions/actionTypes';
import safeSaga from '../safeSaga';
import {
  cartProductCartSelector,
  cartProductFulfillmentSelector,
  productDetailsSelector,
  configuratorSelector,
} from '../../selectors';
import logNewRelicCustom from '../../utils/logNewRelicCustom';

function* log({ actionName, payload }) {
  const {
    partnerProductId,
    productName,
    productId,
    siteId,
  } = yield select(productDetailsSelector);

  const {
    savedProductChoices,
    selectedChoices,
  } = yield select(configuratorSelector);

  const cart = yield select(cartProductCartSelector);
  const fulfillment = yield select(cartProductFulfillmentSelector);

  logNewRelicCustom(actionName, {
    source: 'PRODUCT_CONFIGURATOR_PRODUCT',
    'app-payload': JSON.stringify(payload),
    'app-productInfo-partnerProductId': partnerProductId,
    'app-productInfo-productName': productName,
    'app-productInfo-productId': productId,
    'app-productInfo-siteId': siteId,
    'app-productInfo-url': window?.location?.href,
    'app-productInfo-savedProductChoices': JSON.stringify(savedProductChoices),
    'app-productInfo-selectedChoices': JSON.stringify(selectedChoices),
    'app-productInfo-cart': JSON.stringify(cart),
    'app-productInfo-fulfillment': JSON.stringify(fulfillment),
  });
}

export default function* logNewRelicSaga() {
  yield takeLatest(LOG_TO_NEW_RELIC, safeSaga(log));
}
