/* eslint-disable no-restricted-syntax */
import map from 'lodash/map';
import {
  isActivateSelectionChoice,
} from '../outOfStockUtils';

const availableOptions = (options, choices, choiceAvailability) => {
  const showUiOptions = {};
  if (!choiceAvailability || !Object.keys(choiceAvailability).length) {
    return options;
  }
  const availableOptionChoice = map(choices, (choice) => ({
    ...options[choice.optionId],
    choices: options[choice.optionId].choices.map((ch) => (choiceAvailability[ch] !== false
      || choices[ch].internalName.substring(0, 3) === 'No_') && ch)
      .filter((x) => x !== false),

  }));

  const mapped = Object.values(availableOptionChoice);
  for (const value of mapped) showUiOptions[value.id] = value;

  return showUiOptions;
};
const optionLeveChoices = (choices, changedOptions, choiceAvailability) => {
  const modifChoices = {};
  for (const val of Object.values(changedOptions)) {
    // we need to check and make sure that every single option has
    // only one default selected choice before we return availableOptions
    const ch = val?.choices?.length ? val?.choices : [];
    if (ch.length) {
      const hasSelectedChoice = ch.some((el) => choices[el].isSelected && !choices[el].isDisabled);
      const choicesWithNo = ch.filter((c) => choices[c].internalName.substring(0, 3) === 'No_')[0];
      const choicesToSelect = choicesWithNo || ch.filter((c) => !choices[c].isDisabled)[0];
      if (!hasSelectedChoice) {
        modifChoices[choicesToSelect] = isActivateSelectionChoice(choices[choicesToSelect], true);
      }
    }
  }

  for (const key in choiceAvailability) {
    if (choices[key] && !choiceAvailability[key]) {
      modifChoices[key] = isActivateSelectionChoice(choices[key], false);
    }
  }
  return modifChoices;
};

export const optionsChoiceMappedByOption = (options, choices, choiceAvailability, choicesBySku) => {
  const optionsModified = availableOptions(options, choices, choiceAvailability, choicesBySku);
  const choicesModfied = optionLeveChoices(choices, optionsModified, choiceAvailability);
  return [
    optionsModified,
    choicesModfied,
  ];
};
