import { takeLatest, put } from 'redux-saga/effects';
import { updateProductInfo, thdProductDetailsUpdated } from '../../actions/pricingActions';
import { UPDATE_THD_PRODUCT_DETAILS } from '../../actions/actionTypes';
import safeSaga from '../safeSaga';

export function* addProductDetails(data) {
  yield put(updateProductInfo({
    newSkus: data?.thdProductDetails?.newSkus || null,
    thdDetails: data?.thdProductDetails?.thdDetails
  }));
  yield put(thdProductDetailsUpdated());
}

export default function* thdProductDetailsSaga() {
  yield takeLatest([UPDATE_THD_PRODUCT_DETAILS], safeSaga(addProductDetails));
}
