import axios from 'axios';
import config from '../config';

export default (folder, omsId, isDefault) => {
  const url = `${config.eleanorBaseUrl}/api/mediagallery`;
  const params = {
    folder,
    omsId,
    isDefault,
  };

  return axios.post(url, params)
    .then((response) => response.data);
};
