import {
  createSelector,
} from 'reselect';

export const hydrationSelector = createSelector(
  (state) => state.hydration,
  (hydration) => hydration,
);

export const hydrationFeatureSelector = (feature) => createSelector(
  hydrationSelector,
  (hydration) => {
    if (hydration && hydration[feature]) {
      return hydration[feature];
    }
    return undefined;
  },
);

export default hydrationSelector;
