import { takeLatest, put, select } from 'redux-saga/effects';
import { CHECK_AVAILABILITY,
  GET_EARLIEST_DELIVERY_OPTIONS,
  THD_PRODUCT_DETAILS_UPDATED } from '../../actions/actionTypes';
import safeSaga from '../safeSaga';
import { productDetailsSelector } from '../../selectors';

function* checkAvailability() {
  const productDetails = yield select(productDetailsSelector);

  if (productDetails && productDetails.attributes && productDetails.attributes.isMajorAppliance === 'True') {
    yield put({ type: GET_EARLIEST_DELIVERY_OPTIONS });
  }
}

export default function* availabilitySaga() {
  yield takeLatest([CHECK_AVAILABILITY, THD_PRODUCT_DETAILS_UPDATED], safeSaga(checkAvailability));
}
