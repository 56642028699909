/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
import { useLifeCycleEventBus } from '../hooks/useLifeCycleEventBus';

export const getProductConfiguratorLifeCycleEvents = (props) => {
  // This error can come from Global Events Orchestator
  const err = useLifeCycleEventBus('add-to-cart.error');
  const { assembleItemAtStore, assembleItemAtHome, productAddOns } = useLifeCycleEventBus(
    'configurator.assembleItemAtStore'
  );
  const { configuratorSubscribe, frequency } = useLifeCycleEventBus(
    'configurator.configurator_subscribe'
  );
  const { qty } = useLifeCycleEventBus('configurator.configurator_quantity');
  const { method } = useLifeCycleEventBus('configurator.fulfillment_changed');
  const { fireRules } = useLifeCycleEventBus('configurator.rules');

  return {
    err,
    assembleItemAtStore,
    assembleItemAtHome,
    productAddOns,
    configuratorSubscribe,
    frequency,
    qty,
    method,
    fireRules,
  };
};

export const getProductConfiguratorMemoProperties = (props) => {
  const {
    method,
    type,
    attributes,
    assembleItemAtHome,
    productAddOns,
    qty,
    productInfo,
    cartProduct,
    assembleItemAtStore,
    skuMap,
    fireRules,
  } = props;

  const methodData = useMemo(() => method, [method]);
  const fulfillmentData = useMemo(() => type, [type]);
  const attributesData = useMemo(() => attributes, [attributes]);
  const assembleItemAtHomeData = useMemo(() => assembleItemAtHome, [assembleItemAtHome]);
  const productAddOnsData = useMemo(() => productAddOns, [productAddOns]);
  const qtyData = useMemo(() => qty, [qty]);
  const productInfoData = useMemo(() => productInfo, [productInfo]);
  const cartProductMemo = useMemo(() => cartProduct, [cartProduct]);
  const assembleItemAtStoreData = useMemo(() => assembleItemAtStore, [assembleItemAtStore]);
  const configurationErrorData = useMemo(
    () => skuMap?.configurationError,
    [skuMap?.configurationError]
  );
  const fireRulesData = useMemo(() => fireRules, [fireRules]);

  const cart = cartProductMemo?.configuration?.cart;

  return {
    methodData,
    fulfillmentData,
    attributesData,
    assembleItemAtHomeData,
    productAddOnsData,
    qtyData,
    productInfoData,
    cartProductMemo,
    assembleItemAtStoreData,
    configurationErrorData,
    fireRulesData,
    cart,
  };
};

export default { getProductConfiguratorLifeCycleEvents, getProductConfiguratorMemoProperties };
