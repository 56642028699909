import { takeLatest, call } from 'redux-saga/effects';
import safeSaga from '../safeSaga';
import { UPDATE_CART_PRODUCT_CONFIGURATION, CONFIGURATOR_SET_HYDRATION_COMPLETE } from '../../actions/actionTypes';
import isPreviewProduct from '../../utils/isPreviewProduct';

const removeChildrenByDataComponent = (substrings) => {
  substrings.forEach((substring) => {
    // Select all divs with data-component substring
    const elements = document.querySelectorAll(`div[data-component*="${substring}"]`);
    elements.forEach((element) => {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    });
  });
};

const handlePreview = () => {
  const product = {
    configuredProductLabel: '[Product Title]',
  };
  LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_choice_selected_event', product);
  removeChildrenByDataComponent([
    'ShareBelt',
    'buybox',
    'salient-points',
    'breadcrumbs',
    'returns-messaging',
    'product-details:ProductDetailsBrandCollection',
    'product-details:ProductDetailsRatings',
    'what-we-offer',
    'delivery-and-installation',
  ]);

  let productDetails = document.querySelector('div[data-component*="product-details:ProductDetailsBrandCollection"]');
  if (productDetails) {
    let newSpan = document.createElement('span');
    newSpan.textContent = '[Brand]';
    productDetails.appendChild(newSpan);
  }

  // Hide product-info-bar number
  const productInfo = document.querySelector('.product-info-bar');
  if (productInfo) {
    productInfo.style.display = 'none';
  }
  // Hide all the sections outside of the product-configurator
  document.querySelectorAll('section').forEach((section) => {
    if (!section.closest('div[data-component="ProductConfigurator"]')) {
      const sectionToModify = section;
      sectionToModify.style.display = 'none';
    }
  });
};

function* hideComponent() {
  if (isPreviewProduct) {
    yield call(handlePreview);
  }
}

export default function* previewSaga() {
  yield takeLatest([UPDATE_CART_PRODUCT_CONFIGURATION, CONFIGURATOR_SET_HYDRATION_COMPLETE], safeSaga(hideComponent));
}
