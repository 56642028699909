import {
  VISUALIZER,
  CONFIGURATOR,
  CUSTOMER_RATINGS,
  PRODUCT_OVERVIEW,
  SPECIFICATIONS,
  QUESTIONS_AND_ANSWERS,
  CUSTOMER_REVIEWS,
} from './constants';

const hydrationLoadOrder = {
  [VISUALIZER]: {
    feature: VISUALIZER,
    timeout: 6000,
    next: PRODUCT_OVERVIEW,
    showSkeleton: false,
  },
  [CUSTOMER_RATINGS]: {
    feature: CUSTOMER_RATINGS,
    timeout: 700,
  },
  [CONFIGURATOR]: {
    feature: CONFIGURATOR,
    timeout: 5000,
    next: CUSTOMER_RATINGS,
  },
  [PRODUCT_OVERVIEW]: {
    feature: PRODUCT_OVERVIEW,
    timeout: 3000,
    next: SPECIFICATIONS,
  },
  [SPECIFICATIONS]: {
    feature: SPECIFICATIONS,
    timeout: 1000,
    next: QUESTIONS_AND_ANSWERS,
  },
  [QUESTIONS_AND_ANSWERS]: {
    feature: QUESTIONS_AND_ANSWERS,
    timeout: 2500,
    next: CUSTOMER_REVIEWS,
  },
  [CUSTOMER_REVIEWS]: {
    feature: CUSTOMER_REVIEWS,
    timeout: 5,
  },
};

export default hydrationLoadOrder;
