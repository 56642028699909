import React from 'react';
import PropTypes from 'prop-types';

import isFunction from 'lodash/isFunction';
import InfoLink from '../../InfoLink';
import Markdown from '../../Markdown';
import { Header, Label, Value } from './styles';
import { Definition } from '../styles';

const ChoiceSectionHeaderV2 = ({
  label,
  value,
  definitionHeader,
  definitionMarkdown,
  definition: Def,
  ...rest
}) => {
  const modalHeader = definitionHeader || label;

  let infoLink = null;
  const renderedDefinition = isFunction(Def) ? <Def /> : Def;
  if (renderedDefinition) {
    infoLink = (
      <InfoLink title={modalHeader}>
        {renderedDefinition}
      </InfoLink>
    );
  } else if (definitionMarkdown) {
    infoLink = (
      <InfoLink title={modalHeader}>
        <Definition>
          <Markdown source={definitionMarkdown} />
        </Definition>
      </InfoLink>
    );
  }
  return (
    <Header {...rest}>
      <Label>{label}: </Label>
      {value && <Value>{value}</Value> }
      {infoLink}
    </Header>
  );
};

ChoiceSectionHeaderV2.propTypes = {
  children: PropTypes.node,
  definitionHeader: PropTypes.string, // defaults to `children` text
  definitionMarkdown: PropTypes.string,
  definition: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  label: PropTypes.string,
  value: PropTypes.string,
};

ChoiceSectionHeaderV2.defaultProps = {
  definitionHeader: '',
  definitionMarkdown: '',
  definition: null,
  label: '',
  value: '',
  children: <></>,
};

export {
  Header,
  Definition,
};

export default ChoiceSectionHeaderV2;
