/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import merge from 'lodash/merge';
import map from 'lodash/map';

const useQuantity = (cart, skuMap, items, newData, onChangeGraphData, deliveryTime, qtyData, qty) => {
  useEffect(() => {
    const getMap = () => map(cart, (x) => {
      const choiceQuantity = skuMap.subSkus.find((s) => Number(s.sku) === x.itemId);
      const multipliedQuantity = choiceQuantity ? choiceQuantity.multiplier * qty : qty;

      return x.quantity = multipliedQuantity;
    });
    getMap();
    const newQuantityData = {
      product: {
        configuration: {
          deliveryTime,
          cart,
          items
        },
      }
    };
    const quantityData = merge(
      {},
      { ...newData },
      { ...newQuantityData },
    );

    return onChangeGraphData(quantityData);
  }, [qtyData]);
};

export default useQuantity;