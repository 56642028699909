import { createAction } from '../utils/createAction';
import {
  GET_EARLIEST_DELIVERY_OPTIONS,
  GET_EARLIEST_DELIVERY_OPTIONS_SUCCESS,
  GET_EARLIEST_DELIVERY_OPTIONS_FAILURE,
  UPDATE_EARLIEST_DELIVERY_TIME,
  GET_EARLIEST_DELIVERY_OPTIONS_STARTED,
  SET_ZIP,
  CHECK_AVAILABILITY,
  SET_SHOW_ZIPCODE_AND_CHECK,
  GET_EARLIEST_DELIVERY_FROM_MODAL,
  REST_EARLIEST_DELIVERY,
} from './actionTypes';

// actions
export const getEarliestDeliveryOptionsStarted = createAction(GET_EARLIEST_DELIVERY_OPTIONS_STARTED);

export const getEarliestDeliveryOptionsSuccess = createAction(GET_EARLIEST_DELIVERY_OPTIONS_SUCCESS);

export const getEarliestDeliveryOptionsFailure = createAction(GET_EARLIEST_DELIVERY_OPTIONS_FAILURE);

export const updateEarliestDelivery = createAction(
  UPDATE_EARLIEST_DELIVERY_TIME, 'earliestDeliveryDate',
  'zipCode', 'availabilityStatus',
);

export const getEarliestDelivery = createAction(GET_EARLIEST_DELIVERY_OPTIONS);

export const checkAvailability = createAction(CHECK_AVAILABILITY);

export const setZip = createAction(SET_ZIP, 'zipCode');

export const setShowZipCodeAndCheck = createAction(SET_SHOW_ZIPCODE_AND_CHECK, 'showZipCodeAndCheck');

export const setIsFromModal = createAction(GET_EARLIEST_DELIVERY_FROM_MODAL, 'fromModal');

export const resetEarliestDelivery = createAction(REST_EARLIEST_DELIVERY);
