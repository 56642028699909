import React from 'react';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import { Option } from '@gcc/configurator';

export const getRenderOptions = ({ options, hasHiddenTag }) => {
  const renderOptionsFlow = flow(
    values,
    filter(
      (option) => option.isActive && !option.isDependent && !option.isInternalOnly && !hasHiddenTag(option)
    ),
    sortBy('sequence'), // property in the array of options
    map((option) => {
      return (
        <section data-testid={`template-option_${option.presentationName}`}>
          <Option key={option.id} option={option} />
        </section>
      );
    })
  );

  const renderOptions = renderOptionsFlow(options);

  return renderOptions;
};
