import { createSelector } from 'reselect';
import pick from 'lodash/pick';

export const skusSelector = createSelector(
  (state) => state.skuMap,
  (skuMap) => {
    const baseSku = {
      ...pick(skuMap, ['sku', 'skuType', 'multiplier']),
      precedence: 0,
    };

    return [
      baseSku,
      ...skuMap.subSkus,
    ];
  },
);
