import { takeLatest, all, select } from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import safeSaga from '../safeSaga';
import { configSelector, configuratorSelector } from '../../selectors/index';

/**
 * Sometimes, the EXECUTE_RULES_SUCCESS action will return choices that should be selected. This
 * function will check if they are in: state => configurator => savedProductChoices. If not, it
 * will select them.
 */
function* updateSelectedChoices(action) {
  const { selectedChoices, choices } = action;
  const { savedProductChoices } = yield select(configuratorSelector);
  const { partnerProductId, productId } = yield select(configSelector);
  // if choices and selectedChoices loaded
  if (choices && Object.keys(choices).length > 0 && selectedChoices && Object.keys(selectedChoices).length > 0) {
    const choicesToUpdate = selectedChoices.map((selectedChoice) => {
      //  just return null if we don't know how to read: state => configurator => savedProductChoices => [product] => [partnerProduct] => selectChoices
      if (!savedProductChoices || !savedProductChoices[productId] || !savedProductChoices[productId][partnerProductId]) {
        return null;
      }

      // return null if the selectedChoice returned from execute rules success is already in: state => configurator => savedProductChoices => [product] => [partnerProduct] => selectChoices
      return null;
    }).filter((x) => x !== null);

    // filter out null responses and fire all updates.
    if (choicesToUpdate.length > 0) {
      yield all(choicesToUpdate);
    }
  }
}

export default function* afterRulesExecuted() {
  yield takeLatest('GCC_CONFIGURATOR/EXECUTE_RULES_SUCCESS', safeSaga(updateSelectedChoices));
}