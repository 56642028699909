import flow from 'lodash/fp/flow';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import join from 'lodash/fp/join';
import isEmpty from 'lodash/fp/isEmpty';
import filter from 'lodash/fp/filter';
import _getSelectedChoicesForVisualization from '../actions/getSkuMap/_getSelectedChoicesForVisualization';
import { CUSTOM_CONFIG_BASED } from './pipFiguratorType';

const makeSkuToken = ({ sku, multiplier }) => {
  if (!sku || multiplier === 0) {
    return '';
  }
  if (multiplier === 1) {
    return sku;
  }

  return `${sku}-x${multiplier}`;
};

const searhTokenSubSkusDefault = ({ subSkus }) => flow(
  sortBy('precedence'),
  map(makeSkuToken),
  join('_'),
)(subSkus);

const searchTokenSubSkuForStormDoors = ({ subSkus }) => flow(
  filter('sku'),
  sortBy('precedence'),
  map(makeSkuToken),
  join('_'),
)(subSkus);

const getSearchTokenForSubSkuByProductType = (skuMap, product) => {
  if (product.details.productType === 'Storm Doors') {
    return searchTokenSubSkuForStormDoors(skuMap);
  }
  return searhTokenSubSkusDefault(skuMap);
};
// Here we get Selected choices for no Sku product
const getSelectedChoicesNoTag = ({ id, isSelected }) => {
  if (isSelected === true) {
    return `${id}`;
  }
  return `${id}-x`;
};

const getSearchTokenForNoSkuProducts = (choices) => flow(
  sortBy('visualizedOptionSequence'),
  map(getSelectedChoicesNoTag),
  join('_'),
)(choices);

const getNoSkuToken = (choices, productId, options, visualizedSiteProductOptions) => {
  const choicesToVisualize = _getSelectedChoicesForVisualization(options, choices, visualizedSiteProductOptions);

  if (choicesToVisualize) {
    return `${productId}_${getSearchTokenForNoSkuProducts(choicesToVisualize)}`;
  }
  return '';
};

// I generate Product Type Specific Scene7 SearchToken -- test
export default (skuMap, product, choices, productId, options, visualizedSiteProductOptions) => {
  let searchToken;
  // we will need to set up bypassSkuMap in the store or just pass empty string  for now
  const { bypassSkuMap, pipfiguratorType } = product.details.attributes || '';
  if ((bypassSkuMap && bypassSkuMap === 'True')
      || (pipfiguratorType && pipfiguratorType === CUSTOM_CONFIG_BASED)) {
    searchToken = getNoSkuToken(choices, productId, options, visualizedSiteProductOptions);
  } else if (skuMap.sku) {
    searchToken = makeSkuToken(skuMap);
  } else if (!isEmpty(skuMap.subSkus)) {
    searchToken = getSearchTokenForSubSkuByProductType(skuMap, product);
  } else {
    searchToken = 'no-configuration-value';
  }
  return searchToken;
};
