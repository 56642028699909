import makeFractionalInchDropdown from './_makeFractionalInchDropdown';

const eighths = [
  (0 / 8),
  (1 / 8),
  (2 / 8),
  (3 / 8),
  (4 / 8),
  (5 / 8),
  (6 / 8),
  (7 / 8),
];

export default makeFractionalInchDropdown(eighths);
