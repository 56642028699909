import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  GET_PRICING,
  UPDATE_CART_PRODUCT_GALLERY,
  GET_PAGE_META_OVERVIEW_SUCCESS,
  THD_PRODUCT_DETAILS_UPDATED,
  PRICING_AVAILABLE,
  UPDATE_CART_PRODUCT,
} from '../../actions/actionTypes';
import safeSaga from '../safeSaga';
import titleParser from '../../utils/seo/titleParser';
import {
  configuratorSelector,
  pageMetaOverviewSelector,
  cartProductSelector,
  cartProductCartSelector,
  skuMapSelector,
  productInfoNonDefaultSelector,
  configSelector
} from '../../selectors';
import getPricing from '../pricingSaga/getPricing';
import checkAvailability from '../availabilitySaga/availabilitySaga';
import getPageMetaOverview from '../../actions/pageMetaOverviewActions';
import { updateDynamicTabTitle } from '../../actions/updateDynamicTabTitle';
import addConfigurationToCartV2 from '../../actions/cartActions/addConfigurationToCartV2';
import { mapskus } from '../../actions/fulfillmentActions/getItemsBySkuMap';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE } from '../../actions/hydration/constants';

// eslint-disable-next-line require-yield
function* turnOffLoading() {
  if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, true);
  }
}

function* harmonyData(action) {
  switch (action.type) {
  case UPDATE_CART_PRODUCT:
  case PRICING_AVAILABLE: {
    yield put(addConfigurationToCartV2());
    break;
  }
  case 'GCC_CONFIGURATOR/LOAD_OPTIONS_SUCCESS': {
    const pageMetaOverview = yield select(pageMetaOverviewSelector);
    const { pageMeta } = pageMetaOverview;
    if (!pageMeta) {
      yield put(getPageMetaOverview());
    }
    break;
  }
  case 'GCC_CONFIGURATOR/CHOICE_CHANGED_SUCCESS': {
    const configurator = yield select(configuratorSelector);
    const pageMetaOverview = yield select(pageMetaOverviewSelector);
    const { options, choices } = configurator;
    const { pageMeta } = pageMetaOverview;
    if (!pageMeta) {
      break;
    }
    const harmonyTitle = titleParser(pageMeta.DynamicPageTitle, options, choices);
    const dynamicTabTitle = titleParser(pageMeta.DynamicTabTitle, options, choices);
    yield put(updateDynamicTabTitle(dynamicTabTitle || harmonyTitle));
    const product = {
      configuredProductLabel: harmonyTitle,
    };
    yield LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_choice_selected_event', product);
    break;
  }
  case UPDATE_CART_PRODUCT_GALLERY: {
    const { harmonyGallery } = action;
    if (!harmonyGallery) {
      break;
    }
    const configurator = yield select(configuratorSelector);
    const pageMetaOverview = yield select(pageMetaOverviewSelector);
    const { options, choices } = configurator;
    const { pageMeta } = pageMetaOverview;
    const harmonyTitle = titleParser(pageMeta?.DynamicPageTitle, options, choices);
    const extMedia = {
      configuredProductLabel: harmonyTitle,
      externalMedia: [
        ...harmonyGallery
      ]
    };

    const product = {
      configuredProductLabel: harmonyTitle,
      configuredAssetUrl: harmonyGallery?.[0]?.thumbnail,
    };

    yield LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_media_gallery_event', extMedia);
    yield LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_sticky_nav_event', product);
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    break;
  }
  case THD_PRODUCT_DETAILS_UPDATED: {
    const product = yield select(cartProductSelector);
    const productInfoNonDefault = yield select(productInfoNonDefaultSelector);
    const config = yield select(configSelector);
    const skuMap = yield select(skuMapSelector);
    const items = yield call(mapskus, productInfoNonDefault, config, skuMap, product?.fulfillment);
    if (items.length > 0) {
      // LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_available_items", { items });
    }
    return LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_edit', { isConfiguratorEditMode: false });
  }
  default:
    // eslint-disable-next-line no-case-declarations
    const cart = yield select(cartProductCartSelector);
    if ((cart && cart?.length === 0) || !cart?.[0]?.itemId) {
      yield put(addConfigurationToCartV2());
    }
    break;
  }

  return action;
}

const safeGetPricing = safeSaga(getPricing);

export default function* harmonySaga() {
  yield takeLatest(
    [
      UPDATE_CART_PRODUCT_GALLERY,
      GET_PAGE_META_OVERVIEW_SUCCESS,
      PRICING_AVAILABLE,
      UPDATE_CART_PRODUCT,
      'GCC_CONFIGURATOR/LOAD_OPTIONS_SUCCESS',
      'GCC_CONFIGURATOR/CHOICE_CHANGED_SUCCESS',
      THD_PRODUCT_DETAILS_UPDATED,
    ],
    safeSaga(harmonyData)
  );
  yield takeLatest(
    [
      'GCC_CONFIGURATOR/LOAD_OPTIONS_SUCCESS',
      'GCC_CONFIGURATOR/CHOICE_CHANGED_SUCCESS',
    ],
    safeSaga(turnOffLoading)
  );
  yield takeLatest(
    [
      THD_PRODUCT_DETAILS_UPDATED
    ],
    safeSaga(checkAvailability)
  );
  yield takeLatest(
    [
      GET_PRICING,
    ],
    safeGetPricing
  );
}