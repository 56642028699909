import isEmpty from 'lodash/isEmpty';
import { executeRules } from '@gcc/configurator/dist/actions/rulesActions';
import { createCartConfiguration } from '../fulfillmentActions/createCartConfiguration';
import { mapskus } from '../fulfillmentActions/getItemsBySkuMap';
import { addGccConfigurationToCart } from '.';
import addConfigurationToCartV2 from './addConfigurationToCartV2';
import { cartProductFulfillmentSelector, lineItemGuidSelector, productInfoNonDefaultSelector } from '../../selectors';

export const addSpecialOrderToCart = (cartOptions, fromPaypal = false) => async (dispatch, getState) => {
  const state = getState();
  const {
    config,
    skuMap,
  } = state;

  try {
    const fulfillment = cartProductFulfillmentSelector(getState());
    const lineItemGuid = lineItemGuidSelector(getState());
    const productInfoNonDefault = productInfoNonDefaultSelector(getState());
    // escape hatch to eject to carting stock products
    // there is a scenario where cart drawers have customATC true but they
    // SHOULD NOT so if customer gets into this state let them checkout
    if (!skuMap?.isSpecialOrder) {
      return dispatch(addConfigurationToCartV2());
    }

    const items = mapskus(productInfoNonDefault, config, skuMap, fulfillment);

    if (!fromPaypal) {
      await dispatch(executeRules());
    }

    const retVal = await createCartConfiguration({ overrideStoreNumber: cartOptions?.storeId });

    if (retVal && retVal.status === 200) {
      const cartData = await dispatch(addGccConfigurationToCart(retVal.data.ConfigurationGuid, items, cartOptions));

      if (fromPaypal) {
        return cartData;
      }

      // If triggered from FSS (Fulfillment Store Selector) drawer
      // We need to do this so we don't endlessly pollute DOM with new iframes
      if (cartOptions?.triggeredFromStoreDrawer) {
        window.postMessage({
          action: 'event',
          type: 'cart-pss-atc-event',
          cartModel: cartData,
          cartOptions: {
            host: window?.location?.origin || 'https://www.homedepot.com',
            channel: cartOptions?.channel || 'desktop',
            skipMisship: true
          }
        });
      } else {
        // If triggered from Buybox Add To Cart button
        window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
          cartReqParams: cartData,
          cartOptions,
        });
      }
      return !isEmpty(lineItemGuid) && !config.isCopy ? lineItemGuid : null;
    }
    return null;
  } catch (err) {
    console.log('error', err);
    return null;
  }
};
