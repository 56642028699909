import React from 'react';
import PropTypes from 'prop-types';
import {
  ChoiceDescription,
  ChoiceDescriptionLabel,
  BoxChoice,
  ChoiceImage,
  ChoiceBorder,
} from './styles';
import getIndexableProp from '../../utils/getIndexableProp';

const ChoiceBox = ({
  imageSrc,
  onClick,
  label,
  selected,
  children,
  href,
  indexable,
  ...rest
}) => (
  <ChoiceBorder selected={selected} {...rest}>
    <BoxChoice role="presentation" onClick={onClick}>
      {
        children
        || (
          <>
            <ChoiceImage>
              <img src={imageSrc} alt={label} height="100%" width="100%" />
            </ChoiceImage>
            <ChoiceDescription>
              <ChoiceDescriptionLabel>
                {/* These <a> tags with preselected choices are added on each choice for seo.
                This enables crawlers to know about the multiple configuration of the one product
                - nofollow is added to temporarily disable these links for crawler */}
                {(href && href.length > 0) ? (
                  <a
                    href={href}
                    className="choice-config-link"
                    {...getIndexableProp(indexable)}
                    onClick={(e) => e.preventDefault()}
                  >
                    {label}
                  </a>
                )
                  : (
                    label
                  )}
              </ChoiceDescriptionLabel>
            </ChoiceDescription>
          </>
        )
      }
    </BoxChoice>
  </ChoiceBorder>
);

ChoiceBox.propTypes = {
  onClick: PropTypes.func.isRequired,
  imageSrc: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.node,
  href: PropTypes.string,
  indexable: PropTypes.bool,
};

ChoiceBox.defaultProps = {
  imageSrc: '',
  label: '',
  selected: false,
  children: null,
  href: '',
  indexable: false,
};

export default ChoiceBox;
