import identity from 'lodash/identity';
import {
  APP_MOUNT,
  UPDATE_SKUMAP,
  UPDATE_SKUMAP_STARTED,
  UPDATE_SKUMAP_FAILED,
  UPDATE_SKUMAP_FINISHED,
  UPDATE_SKUMAP_SPECIALORDER,
  UPDATE_SKUMAP_CONFIGURATION_ERRORS,
} from '../../actions/actionTypes';
import onUpdateSkuMap from './onUpdateSkuMap';
import onUpdateSkuMapFailed from './onUpdateSkuMapFailed';
import onSkuMapStart from './onSkuMapStart';
import onUpdateSkuMapConfigurationErrors from './onUpdateSkuMapConfigurationErrors';

const initialState = {
  isLoading: false,
  isSpecialOrder: false,
  sku: '',
  hash: '',
  subSkus: [],
  configurationError: false,
  multiplier: 1,
  allSkus: [],
  skuType: '',
  isValid: false,
};

const setConstant = (constants) => (state) => ({ ...state, ...constants });

const handlers = {
  [APP_MOUNT]: () => ({ ...initialState }),
  [UPDATE_SKUMAP_SPECIALORDER]: setConstant({ isSpecialOrder: true }),
  [UPDATE_SKUMAP_STARTED]: onSkuMapStart,
  [UPDATE_SKUMAP_FINISHED]: setConstant({ isLoading: false }),
  [UPDATE_SKUMAP]: onUpdateSkuMap,
  [UPDATE_SKUMAP_FAILED]: onUpdateSkuMapFailed,
  [UPDATE_SKUMAP_CONFIGURATION_ERRORS]: onUpdateSkuMapConfigurationErrors,
};

export default (state = initialState, action) => {
  const reduce = handlers[action.type] || identity;

  return reduce(state, action);
};
