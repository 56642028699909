import {
  GET_PAGE_META_OVERVIEW_SUCCESS,
  UPDATE_PAGE_META_OVERVIEW,
  UPDATE_PAGE_META_LOADING,
  UPDATE_DYNAMIC_TAB_TITLE,
} from '../actions/actionTypes';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
  case GET_PAGE_META_OVERVIEW_SUCCESS: {
    return { ...state };
  }
  case UPDATE_PAGE_META_OVERVIEW: {
    const { pageMeta, loading } = action;
    return { ...state, pageMeta, loading };
  }
  case UPDATE_PAGE_META_LOADING: {
    const { loading } = action;
    return { ...state, loading };
  }
  case UPDATE_DYNAMIC_TAB_TITLE: {
    const { dynamicTabTitle } = action;
    return { ...state, dynamicTabTitle };
  }
  default:
    return state;
  }
};
