import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

const toCamelCase = (obj) => {
  if (!isObject(obj)) {
    return obj;
  } if (isArray(obj)) {
    return obj.map((v) => toCamelCase(v));
  }

  const ret = {};
  Object.entries(obj).forEach(([k, v]) => {
    ret[camelCase(k)] = toCamelCase(v);
  });

  return ret;
};

export default toCamelCase;
