import React, { memo } from 'react';
import useIsMobile from '../hooks/useIsMobile';

const defaults = {
  mobileMaxWidth: 576, // px
};

const withIsMobile = (Component, options = {}) => {
  const params = {
    ...defaults,
    ...options,
  };

  const ResponsiveComponent = (props) => {
    const isMobile = useIsMobile(params.mobileMaxWidth);

    return <Component isMobile={isMobile} {...props} />;
  };

  ResponsiveComponent.displayName = `withIsMobile(${
    Component.displayName || Component.name
  })`;

  return memo(ResponsiveComponent);
};

export default withIsMobile;
