import identity from 'lodash/identity';
import appMount from './appMount';
import updateSkuMap from './updateSkuMap';
import updateSkuMapFailed from './updateSkuMapFailed';
import { initialState } from './_defaults';
import {
  APP_MOUNT,
  UPDATE_SKUMAP_FAILED,
  UPDATE_PRODUCTINFO,
} from '../../actions/actionTypes';

const handlers = {
  [APP_MOUNT]: appMount,
  [UPDATE_PRODUCTINFO]: updateSkuMap,
  [UPDATE_SKUMAP_FAILED]: updateSkuMapFailed, // This will no longer make sense when Custom Pricing is implemented
};

export default (state = initialState, action) => {
  const reduce = handlers[action.type] || identity;
  return reduce(state, action);
};
