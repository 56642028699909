import { useDataModel } from '@thd-nucleus/data-sources';
import _first from 'lodash/first';
import { getMappedSkus } from '../sagas/pricingSaga/_utils';
import isPreviewProduct from '../utils/isPreviewProduct';

const useNucleusData = ({
  storeId, itemId, zipCode, skuMap, itemIdForDisplay
}) => {

  const productOptions = {
    skip: storeId === '8119' || !itemId || !skuMap.isValid,
    ssr: false,
    variables: {
      itemId: String(itemId),
      storeId,
      zipCode,
    }
  };

  let productOptionsForNonSpecialOrders = null;
  if (!skuMap.isSpecialOrder) {
    const skuNumbers = getMappedSkus(skuMap);
    // eslint-disable-next-line lodash/preferred-alias
    const id = _first(skuNumbers);
    if (id) {
      productOptionsForNonSpecialOrders = {
        skip: storeId === '8119' || itemIdForDisplay <= 0 || !skuMap.isValid,
        variables: {
          itemId: itemIdForDisplay > 0 ? String(itemIdForDisplay) : String(id),
          storeId,
          zipCode,
        }
      };
    }
  }

  // For products that have state => skuMap.isSpecialOrder = false, we have a different way of determining the itemIds
  // to send to graphql. But not all products work this way. We have some that are non-sku-map and isSpecialOrder = false.
  // For those, we should use the standard method of finding itemId. Unfortunately, there is no real way to know if something
  // is a non-sku-map and isSpecialOrder = false product. In this case, we try the isSpecialOrder = false method and it will
  // return no results. Then we try the standard method.
  const { data, loading, error } = useDataModel('clientOnlyProduct', productOptionsForNonSpecialOrders ?? productOptions);

  // prevent MA re-render showing the bubybox-fulfillment
  if (isPreviewProduct) {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_available_items', {
      items: null
    });
  } else {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_available_items', {
      items: [{
        itemId: productOptionsForNonSpecialOrders?.variables?.itemId ?? productOptions?.variables?.itemId,
        isAnchor: true,
      }]
    });
  }

  return { data, loading, error };
};

export default useNucleusData;
