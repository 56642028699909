import {
  GET_EARLIEST_DELIVERY_OPTIONS_FAILURE,
  GET_EARLIEST_DELIVERY_OPTIONS_SUCCESS,
  GET_EARLIEST_DELIVERY_OPTIONS_STARTED,
  UPDATE_EARLIEST_DELIVERY_TIME, SET_ZIP,
  GET_EARLIEST_DELIVERY_FROM_MODAL,
  REST_EARLIEST_DELIVERY,
  SET_SHOW_ZIPCODE_AND_CHECK
} from '../actions/actionTypes';

export const initialState = {
  isLoading: false,
  isError: false,
  earliestDeliveryDate: null,
  deliveryLoadingComplete: false,
  zipCode: '',
  showZipCodeAndCheck: true,
  fromModal: false,
  availabilityStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_EARLIEST_DELIVERY_TIME: {
    const { zipCode, earliestDeliveryDate, availabilityStatus } = action;
    return {
      ...state,
      isError: earliestDeliveryDate == null,
      isLoading: false,
      earliestDeliveryDate,
      deliveryLoadingComplete: true,
      zipCode,
      availabilityStatus,
    };
  }
  case GET_EARLIEST_DELIVERY_OPTIONS_STARTED: {
    return {
      ...state,
      isLoading: true,
    };
  }
  case GET_EARLIEST_DELIVERY_FROM_MODAL: {
    const { fromModal } = action;
    return {
      ...state,
      fromModal,
    };
  }
  case GET_EARLIEST_DELIVERY_OPTIONS_SUCCESS:
    return {
      ...state,
      isError: false,
      isLoading: false,
    };
  case GET_EARLIEST_DELIVERY_OPTIONS_FAILURE:
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  case SET_ZIP: {
    const { zipCode } = action;
    return {
      ...state,
      zipCode,
    };
  }
  case SET_SHOW_ZIPCODE_AND_CHECK: {
    const { showZipCodeAndCheck } = action;
    return {
      ...state,
      showZipCodeAndCheck,
    };
  }
  case REST_EARLIEST_DELIVERY: {
    return {
      ...state,
      ...initialState,
    };
  }

  default:
    return state;
  }
};
