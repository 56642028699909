import {
  createAction,
} from 'redux-actions';

import hydrationLoadOrder from './hydrationLoadOrder';

const {
  format,
} = require('date-fns');

export const getDateTime = () => {
  const unixTime = Date.now();
  return ({
    unixTime,
    dateTime: `${format(unixTime, 'MM-DD-YYYY HH:mm:ss:SSS')}`,
  });
};
export const HYDRATE_NEXT = 'HYDRATE_NEXT';

export const hydrateNext = ({
  feature,
  timeout,
}) => (
  createAction(
    `${feature} ${HYDRATE_NEXT}`,
    () => ({
      timeout,
    }),
    () => ({
      feature,
    }),
  )
)({
  feature,
  timeout,
});

export const HYDRATION_START = 'SET_HYDRATION_START';

export const setHydrationStart = (feature) => {
  const {
    next,
    showSkeleton,
  } = hydrationLoadOrder[feature];
  return (
    createAction(
      `${feature} ${HYDRATION_START}`,
      () => ({
        startTime: getDateTime(),
        hydrated: false,
        next,
        showSkeleton,
      }),
      () => ({
        feature,
      }),
    )
  )(feature);
};

export const HYDRATION_COMPLETE = 'SET_HYDRATION_COMPLETE';

export const setHydrationComplete = (feature) => (
  createAction(
    `${feature} ${HYDRATION_COMPLETE}`,
    () => ({
      endTime: getDateTime(),
      hydrated: true,
    }),
    () => ({
      feature,
    }),
  )
)(feature);
