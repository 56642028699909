import { UPDATE_THD_STORE_INFO } from '../actions/actionTypes';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_THD_STORE_INFO:
    if (!action.thdStoreInfo) {
      return initialState;
    }
    return {
      ...state,
      ...action.thdStoreInfo,
    };
  default:
    return state;
  }
};
