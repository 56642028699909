import includes from 'lodash/includes';
import identity from 'lodash/identity';
import getTagsOfType from '../getTagsOfType';

export default (optionOrChoice, tagType, tagValue, textTransform = identity) => (
  includes(
    getTagsOfType(optionOrChoice, tagType, textTransform),
    textTransform(tagValue),
  )
);
