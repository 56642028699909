import axios from 'axios';
import map from 'lodash/map';
import config from '../config';

export const createConfigurationWithSkuPricing = (productId, selections, storeNum, mainImageUrl = '', sku, skuType) => {
  const json = {
    siteId: config.siteId,
    productId,
    selections: map(selections, (selection) => ({
      optionId: selection.optionId,
      choiceValue: selection.choiceId,
      choiceName: selection.choiceValue,
      optionTags: selection.optionTags,
    })),
    storeNum,
    mainImageUrl,
    sku,
    skuType,
  };

  const url = config.skuConfigApiUrl;

  return axios.post(url, json);
};

export const createConfiguration = (productId, selections, storeId, mainImageUrl = '') => {
  const json = {
    siteId: config.siteId,
    productId,
    selections: map(selections, (selection) => ({
      optionId: selection.optionId,
      choiceValue: selection.choiceId,
      choiceName: selection.choiceValue,
      optionTags: selection.optionTags,
    })),
    storeId,
    mainImageUrl,
  };

  const url = `${config.configApiUrl}`;

  return axios.post(url, json);
};

export const createConfigurationAsync = (productId, selections, storeId, mainImageUrl = '') => {
  const json = {
    siteId: config.siteId,
    productId,
    selections: map(selections, (selection) => ({
      optionId: selection.optionId,
      choiceValue: selection.choiceId,
      choiceName: selection.choiceValue,
      optionTags: selection.optionTags,
    })),
    storeId,
    mainImageUrl,
  };

  const url = `${config.configApiUrl}`;

  return Promise.resolve(axios.post(url, json));
};

export const editConfiguration = (productId, selections) => {
  const json = {
    siteId: config.siteId,
    productId,
    selections,
  };

  const url = `${config.configApiUrl}/api/v1/Configuration/Edit/`;

  return axios.post(url, json);
};
