import styled, { css } from 'styled-components';

export const Header = styled.div`
  color: #666;
  margin-bottom: 10px;
  user-select: none;
  pointer-events: none;
  & > span {
    font-size: 1.4rem;
    color: #262626;
  }
`;
export const Label = styled.span``;
export const Value = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-size: inherit;
`;
