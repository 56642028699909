import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import ReactResizeDetector from 'react-resize-detector';
import { horizScrollToElement } from '../../utils/scroll';
import {
  Wrapper,
  GradientLeft,
  LeftArrowBox,
  LeftScrollArrow,
  Slider,
  GradientRight,
  RightArrowBox,
  RightScrollArrow,
  ArrowWrapper,
} from './styles';

class Carousel extends React.Component {
  constructor() {
    super();
    this.state = {
      atLeft: true,
      atRight: false,
    };

    this.slider = React.createRef();
    this.wrapper = React.createRef();
    this.scrollPosition = this.scrollPosition.bind(this);
  }

  componentDidMount() {
    this.setSize();
    window.addEventListener('resize', this.setSize);
  }

  componentDidUpdate() {
    const { isOverflowing } = this.props;
    const { atRight, atLeft } = this.state;
    const isOverflow = !atLeft || !atRight;
    isOverflowing(isOverflow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize);
  }

  setAtLeft(left) {
    const { atLeft } = this.state;
    if (left !== atLeft) {
      this.setState({ atLeft: left });
    }
  }

  setAtRight(right) {
    const { atRight } = this.state;
    if (right !== atRight) {
      this.setState({ atRight: right });
    }
  }

  setSize = () => {
    const wrapperNode = this.wrapper.current;
    const sliderNode = this.slider.current;
    if (wrapperNode && wrapperNode.style && sliderNode && sliderNode.style) {
      wrapperNode.style.height = sliderNode.style.height;
      this.scrollPosition();
    }
  };

  scrollRight = (event) => {
    event.stopPropagation();
    const { onIndexChange, oneItemPerSlide } = this.props;
    const target = this.slider.current;
    const width = target.offsetWidth + target.scrollLeft;
    let childWidth = 0;
    for (let i = 0; i < target.children.length; i += 1) {
      const child = target.children[i];
      childWidth += child.offsetWidth;
      const isLast = target.children.length === i + 1;
      if (isLast || childWidth > width) {
        onIndexChange(i - 1);
        horizScrollToElement(target, child, oneItemPerSlide ? 1 : 0.55);
        return;
      }
    }
  };

  scrollLeft = (event) => {
    event.stopPropagation();

    const target = this.slider.current;
    const scrollRight = target.scrollWidth - target.scrollLeft - target.offsetWidth;
    const width = target.offsetWidth + scrollRight;
    const children = [...target.children].reverse();
    const { onIndexChange, oneItemPerSlide } = this.props;
    let childWidth = 0;
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      childWidth += child.offsetWidth;
      const isLast = children.length === i + 1;
      if (isLast || childWidth > width) {
        onIndexChange(children.length - (i + 2));
        horizScrollToElement(target, child, oneItemPerSlide ? 1 : 0.45);
        return;
      }
    }
  };

  scrollToIndex = (index) => {
    const { oneItemPerSlide } = this.props;
    const target = this.slider.current;
    if (!target.children) {
      return;
    }
    if (index >= 0 && index < target.children.length) {
      horizScrollToElement(target, target.children[index], oneItemPerSlide ? 0 : 0.5);
    }
  };

  scrollPosition() {
    const target = this.slider.current;

    const { scrollLeft, scrollWidth } = target;
    const scrollRight = Math.max(
      0,
      scrollWidth - scrollLeft - Math.ceil(target.getBoundingClientRect().width),
    );

    this.setAtLeft(scrollLeft === 0);
    this.setAtRight(scrollRight === 0);
  }

  render() {
    const { atLeft, atRight } = this.state;
    const {
      oneItemPerSlide, children, index, isOverflowing, onIndexChange, ...rest
    } = this.props;

    return (
      <Wrapper {...rest} ref={this.wrapper}>
        {!atLeft && (
          <div>
            { !oneItemPerSlide && <GradientLeft />}
            <LeftArrowBox oneItemPerSlide={oneItemPerSlide} onClick={this.scrollLeft}>
              <ArrowWrapper>
                <LeftScrollArrow />
              </ArrowWrapper>
            </LeftArrowBox>
          </div>
        )}
        <Slider ref={this.slider} onScroll={this.scrollPosition}>
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this.setSize}
          />
          {children}
        </Slider>
        {!atRight && (
          <div>
            { !oneItemPerSlide && <GradientRight />}
            <RightArrowBox oneItemPerSlide={oneItemPerSlide} onClick={this.scrollRight}>
              <ArrowWrapper>
                <RightScrollArrow />
              </ArrowWrapper>
            </RightArrowBox>
          </div>
        )}
      </Wrapper>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.node,
  isOverflowing: PropTypes.func,
  onIndexChange: PropTypes.func,
  index: PropTypes.number,
  oneItemPerSlide: PropTypes.bool,

};

Carousel.defaultProps = {
  children: [],
  isOverflowing: noop,
  onIndexChange: noop,
  index: 0,
  oneItemPerSlide: false,

};

export default Carousel;
